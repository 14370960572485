<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'auditCrud'"
          crudName="Auditorias"
          newText="Nova Auditoria"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="false"
          :enableDelete="false"
          :enableView="true"
          :enableReset="false"
          :dataForm="dataForm"
          :extra="extra"
          @modal="modal"
        >
        </base-crud>
      </div>
    </div>

    <!-- Modal Edição -->
    <div class="modal fade" id="modalEdicao" tabindex="-1" role="dialog" aria-labelledby="modalEdicaoTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalEdicaoLongTitle">Editar Auditoria</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label>Finalizado em:</label>
                <input v-model="data_modal.finished_at" type="date" class="form-control"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Problemas encontrados:</label>
                <textarea v-model="data_modal.problemns" id="" cols="30" rows="10" class="form-control"></textarea>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-12">
                <label>Sugestões de medidas corretivas:</label>
                <textarea v-model="data_modal.suggestions" id="" cols="30" rows="10" class="form-control"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Conclusões</label>
                <textarea v-model="data_modal.conclusion" id="" cols="30" rows="10" class="form-control"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-primary" @click="save">Salvar Alterações</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      data_modal: {},
      table: "auditTable",
      showMessageImport: false,
      auditCrud: {},
      pages: [],
      url: "",
      columns: ["id", "period", "start_at", "end_at", "professional_id", "actions"],
      tableData: ["id", "period", "start_at", "end_at", "professional_id"],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["id", "period", "start_at", "end_at", "professional_id"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          period: "Período",
          start_at: "Data Início",
          end_at: "Data Fim",
          professional_id: "Responsável",
          actions: "Ações",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {},
        templates: {
          professional_id: function (h, row, index) {
            return row.professional ? row.professional.name : "Sem Nome";
          }
        },
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["professional"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "btn btn-crud check ml-1",
          title: "Editar Auditoria",
          name: "modal",
          icon: "far fa-edit",
        },
      ],
      endPoint: "audits",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    query: function (query) {
      let columns = {
        id: "id",
        title: "title",
        description: "description",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    modal(row){
      const self = this;

      self.data_modal = row.row;

      $('#modalEdicao').modal('show')
    },
    save(){
      const self = this;
      let api = self.$store.state.api + "audit/save-finished";
      
      axios
        .post(api, self.data_modal)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Auditoria gerada com sucesso`,
            "success"
          );
          this.$router.push("/audits");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    }
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>