<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">              
              <div class="row">
                <div class="col-md-2">
                  <label>Período</label>
                  <v-select
                    v-model="audit.period"
                    class="vselect"
                    :options="periods"
                    :reduce="(periods) => periods.period"
                    :label="'period'"
                  />
                </div>
                <div class="col-md-2">
                  <label>Início</label>
                  <input type="date" v-model="audit.start_at" class="form-control">
                </div>
                <div class="col-md-2">
                  <label>Fim</label>
                  <input type="date" v-model="audit.end_at" class="form-control">
                </div>
                <div class="col-md-1 mt-2">
                  <button class="btn btn-primary" @click="getUsers">Buscar</button>
                </div>
                <div class="col-md-2">
                  <label>Auditoria Global</label>
                  <br>
                  <toggle-button
                    color="#15488A"
                    :height="25"
                    v-on:input="setAll()"
                    :disabled="users.length <= 0"
                  />
                </div>
              </div> 
            </div> 
          </div>
          <div class="card" v-if="users.length > 0 && loaded">
            <div class="card-body"> 
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">Nome do Classificador</div>
                    <div class="col-md-2">Qtd Classificações</div>
                    <div class="col-md-2">Qtd para auditar</div>
                    <div class="col-md-2">2% do total</div>
                    <div class="col-md-2">Auditar</div>
                  </div>

                   <div class="row" v-for="(user, index) in users" :key="user.id" style="margin-top: 10px; border-bottom: 1px dotted #222">
                    <div class="col-md-4 mt-1">{{ user.name }}</div>
                    <div class="col-md-2 mt-1">{{ user.atendimentos_count}}</div>
                    <div class="col-md-2"><input type="number" class="form-control mb-1" v-model="user.qtd_audit" :disabled="user.two_percent"></div>
                    <div class="col-md-2">
                      <toggle-button
                        v-model="user.two_percent"
                        color="#15488A"
                        :height="25"
                        v-on:input="setTwoPercent(user, index)"
                      />
                    </div>
                    <div class="col-md-2">
                      <toggle-button
                        v-model="user.audit"
                        color="#15488A"
                        :height="25"
                      />
                    </div>
                  </div>
                </div>  
              </div>   
              <div class="row mt-3">
                <div class="col-md-12 right">
                  <button class="btn btn-primary" @click="save"><i class="fas fa-save mr-0"></i> Salvar</button>
                </div>
              </div>          
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      audit: {},
      users: [],
      loaded: false,
      periods: [
        {
          id: 1,
          period: 'nov/2024'
        },
        {
          id: 2,
          period: 'out/2024'
        },
        {
          id: 3,
          period: 'set/2024'
        },
        {
          id: 4,
          period: 'ago/2024'
        },
        {
          id: 5,
          period: 'jul/2024'
        },
        {
          id: 6,
          period: 'jun/2024'
        },
        {
          id: 7,
          period: 'mai/2024'
        },
        {
          id: 8,
          period: 'abr/2024'
        },
        {
          id: 9,
          period: 'mar/2024'
        },
        {
          id: 10,
          period: 'fev/2024'
        },
        {
          id: 11,
          period: 'jan/2024'
        },
      ],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    setTwoPercent(user, index){
      const self = this;
      
      self.loaded = false;

      user.qtd_audit = Math.ceil(user.atendimentos_count * 0.02);

      if(user.qtd_audit < 10){
        user.qtd_audit = 10;
      }

      self.users[index].audit = true;
      self.users[index].audit = true;

      setTimeout(() => {
        self.loaded = true;
      }, 200);
    },
    setAll(){
      const self = this;

      console.log('global');

      self.users.forEach((element, index) => {
        self.setTwoPercent(element, index);
      });
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "audit/save";

      let fd = {
        users: self.users,
        audit: self.audit
      }

        axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Auditoria gerada com sucesso`,
            "success"
          );
          this.$router.push("/audits");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "audit/get-users";

      axios
        .post(api, self.audit)
        .then((response) => {
          self.users = response.data;
          self.loaded = true;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getConselho(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
</style>