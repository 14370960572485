import Crud from '../views/Audit/Crud'
import Entity from '../views/Audit/Add'
import View from '../views/Audit/View'
import Audit from '../views/Audit/Audit'

let routes = {
    path: '/audit',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/audits/audit/:id?',
            name: 'AuditClassificator',
            component: Audit,
            meta: {
                pageTitle: 'Auditar',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Auditar',
                        active: true,
                    },
                ],
            },
        }, 
        {
            path: '/audits/view/:id?',
            name: 'ViewAudit',
            component: View,
            meta: {
                pageTitle: 'Visualizar Auditoria',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Visualizar Auditoria',
                        active: true,
                    },
                ],
            },
        }, 
        {
            path: '/audits/add',
            name: 'NewAudit',
            component: Entity,
            meta: {
                pageTitle: 'Adicionar Auditoria',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Adicionar Auditoria',
                        active: true,
                    },
                ],
            },
        },       
        {
            path: '/audits',
            name: 'audit',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Auditorias',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Auditorias',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
