<template>
  <div>
    <div class="main-content">
      <div class="row" v-if="audit.professional">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">              
              <div class="row">
                <div class="col-md-2">
                  <label>Aberta por</label>
                  <input type="text" class="form-control" v-model="audit.professional.name" disabled>
                </div>
                <div class="col-md-2">
                  <label>Período</label>
                  <input type="text" class="form-control" v-model="audit.period" disabled>
                </div>
                <div class="col-md-2">
                  <label>Início</label>
                  <input type="datetime-local" v-model="audit.start_at" class="form-control" disabled>
                </div>
                <div class="col-md-2">
                  <label>Fim</label>
                  <input type="datetime-local" v-model="audit.end_at" class="form-control" disabled>
                </div>

                <div class="col-md-2">
                  <button class="btn btn-primary mt-2" @click="downloadPDF"><i class="fas fa-download mr-1"></i>Download PDF</button>
                </div>
                
              </div> 
            </div> 
          </div>
          <div class="row  justify-content-center">
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-success`" size="45">
                    <i class="fas fa-users" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ audit.dashboard.doentes_admitidos }}
                    </h2>
                    <span>Nº de Admitidos</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-info`" size="45">
                    <i class="fas fa-check" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ audit.dashboard.total_classificados }}
                    </h2>
                    <span>Nº de Eventos Classificados</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-info`" size="45">
                    <i class="fas fa-sort-numeric-up" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ audit.dashboard.episodios_classificados_auditados_qtd }}
                    </h2>
                    <span>Nº de eventos selec. para auditar</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-info`" size="45">
                    <i class="fas fa-percent" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ audit.dashboard.episodios_classificados_auditados_perc }}%
                    </h2>
                    <span>% de fichas selecionadas para auditar</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-info`" size="45">
                    <i class="fas fa-clock" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ audit.dashboard.tme }}
                    </h2>
                    <span>Tempo médio até a classificação</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-info`" size="45">
                    <i class="fas fa-clock" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ audit.dashboard.tma }}
                    </h2>
                    <span>Tempo médio de classificação</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-info`" size="45">
                    <i class="fas fa-users" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ audit.dashboard.classificadores }}
                    </h2>
                    <span>Número de Classificadores (enfermeiros)</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-info`" size="45">
                    <i class="fas fa-users" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      0
                    </h2>
                    <span>Número de Classificadores (médicos)</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-success`" size="45">
                    <i class="fas fa-check" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ audit.dashboard.corretos }}%
                    </h2>
                    <span>% de classificações corretas</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-3">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-danger`" size="45">
                    <i class="fas fa-times" style="font-size: 18px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ audit.dashboard.incorretos }}%
                    </h2>
                    <span>% de classificações incorretas</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>            
          </div>
          <div class="card" v-if="audit.classificators.length > 0">
            <div class="card-body"> 
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive-sm">
                  <table
                    role="table"
                    aria-busy="false"
                    aria-colcount="4"
                    class="table b-table"
                  >
                    <thead role="rowgroup" class="">
                      <tr role="row" class="">
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>ID</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>Classificador</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="2"
                          class=""
                        >
                          <div>Fichas a Auditar</div>
                        </th>  
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>Fichas a serem Consensuadas</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="2"
                          class=""
                        >
                          <div>Ações</div>
                        </th>                       
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr
                        role="row"
                        class=""
                        v-for="item in audit.classificators"
                        :key="item.id"
                      >       
                        <td aria-colindex="1" role="cell" class="">
                          {{ item.id }}
                        </td>  
                        <td aria-colindex="1" role="cell" class="">
                          {{ item.professional.name }}
                        </td>              
                        <td aria-colindex="1" role="cell" class="">
                          {{ item.quantity_on_period }}
                        </td>               
                        <td aria-colindex="1" role="cell" class="">
                          {{ item.divergences }}
                        </td>                                                                 
                        <td aria-colindex="4" role="cell" class="">                          
                          <button
                            class="btn btn-crud edit"
                            @click="visualizarItens(item.id)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Auditar Classificador"
                          >
                            <i class="fas fa-check"></i>
                          </button>   
                        </td>
                      </tr>
                      <tr v-if="audit.classificators.length == 0">
                        <td colspan="8" class="centered mt-2">
                          Nenhum classificador para este período de auditoria.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="col-md-6 mx-auto d-flex justify-content-center align-items-center"
              >
                <pagination
                  :records="total"
                  :options="options"
                  :per-page="15"
                  @paginate="getEntity"
                ></pagination>
              </div>
            </div>        
            </div>
          </div>
          
          <div class="card">
            <div class="card-header">
              Prioridades atribuídas
            </div>
            <div class="card-body"> 
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive-sm">
                  <table
                    role="table"
                    aria-busy="false"
                    aria-colcount="4"
                    class="table b-table"
                  >
                    <thead role="rowgroup" class="">
                      <tr role="row" class="">
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>Prioridade Clínica</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>Quantidade</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>Porcentagem</div>
                        </th>                             
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr
                        role="row"
                        class=""
                        v-for="(item, index) in audit.dashboard.episodios_classificados_auditados_tb"
                        :key="index"
                      >       
                        <td aria-colindex="1" role="cell" class="">
                          {{ index }}
                        </td>  
                        <td aria-colindex="1" role="cell" class="">
                          {{ item.qtd }}
                        </td>                        
                        <td aria-colindex="1" role="cell" class="">
                          {{ item.perc }}%
                        </td>    
                      </tr>
                      <tr v-if="audit.classificators.length == 0">
                        <td colspan="8" class="centered mt-2">
                          Nenhum resultado.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="col-md-6 mx-auto d-flex justify-content-center align-items-center"
              >
                <pagination
                  :records="total"
                  :options="options"
                  :per-page="15"
                  @paginate="getEntity"
                ></pagination>
              </div>
            </div>        
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  name: "imageUpload",
  data() {
    return {
      audit: {},
      users: [],
      periods: [
        {
          id: 1,
          period: 'nov/2024'
        },
        {
          id: 2,
          period: 'out/2024'
        },
        {
          id: 3,
          period: 'set/2024'
        },
        {
          id: 4,
          period: 'ago/2024'
        },
        {
          id: 5,
          period: 'jul/2024'
        },
        {
          id: 6,
          period: 'jun/2024'
        },
        {
          id: 7,
          period: 'mai/2024'
        },
        {
          id: 8,
          period: 'abr/2024'
        },
        {
          id: 9,
          period: 'mar/2024'
        },
        {
          id: 10,
          period: 'fev/2024'
        },
        {
          id: 11,
          period: 'jan/2024'
        },
      ],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    downloadPDF(){
      const self = this;

      let url =
        self.$store.state.api +
        "audit/download-pdf/" + self.audit.id 
      
      window.open(url, "_blank").focus();
    },
    visualizarItens(item){
        const self = this;
        self.$router.push("/audits/audit/" + item);
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "audit/get-audit";

      axios
        .post(api, {id: id})
        .then((response) => {
          self.audit = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    imprimirFicha(obj) {
      const self = this;
      let url =
        self.$store.state.url +
        "atendimento/impressao/" +
        obj.id +
        "/" +
        self.user.id;
      window.open(url, "_blank").focus();
    },
    classificacaoLabel(classificacao) {
      if (classificacao == 1) {
        return "Vermelho";
      }

      if (classificacao == 2) {
        return "Laranja";
      }

      if (classificacao == 3) {
        return "Amarelo";
      }

      if (classificacao == 4) {
        return "Verde";
      }

      if (classificacao == 5) {
        return "Azul";
      }

      if (classificacao == 6) {
        return "Branco";
      }

      if (!classificacao) {
        return "Não Classificado";
      }
    },
    classificacaoColor(classificacao) {
      if (classificacao == 1) {
        return "btn-classificacao vermelho";
      }

      if (classificacao == 2) {
        return "btn-classificacao laranja";
      }

      if (classificacao == 3) {
        return "btn-classificacao amarelo";
      }

      if (classificacao == 4) {
        return "btn-classificacao verde";
      }

      if (classificacao == 5) {
        return "btn-classificacao azul";
      }

      if (classificacao == 6) {
        return "btn-classificacao branco";
      }

      if (!classificacao) {
        return "btn-classificacao cinza";
      }
    },
    diffIdade(data_nascimento) {
      const self = this;

      var a = moment();
      var b = moment(data_nascimento);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");

      if (years < 100) {
        return years + " anos, " + months + " meses, " + days + " dias";
      }
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
};
</script>
<style scoped>
</style>