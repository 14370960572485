import Vue from 'vue'
import VueRouter from 'vue-router'

import User from './user'
import Company from './company'
import Specialty from './specialty'
import Flowchart from './flowchart'
import Triage from './triage'
import Setor from './setor'
import Priority from './priority'
import Protocol from './protocol'
import CancellationReason from './cancellation_reason'
import Cbo from './cbo'
import Professional from './professional'
import Advice from './advice'
import RazaoEntrada from './razao_entrada'
import NecessidadesEspeciais from './necessidades_especiais'
import Origin from './origin'
import Profile from './profile'
import Audit from './audit'
import Outcome from './outcomes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    User,
    Company,
    Specialty,
    Flowchart,
    Triage,
    Setor,
    Priority,
    Protocol,
    CancellationReason,
    Cbo,
    Professional,
    Advice,
    RazaoEntrada,
    NecessidadesEspeciais,
    Origin,
    Profile,
    Audit,
    Outcome,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/chat',
      name: 'chat',
      component: () => import('@/views/Chat/Chat.vue'),
      meta: {
        pageTitle: 'Chat',
        breadcrumb: [
          {
            text: 'Chat',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  const access_nivel = localStorage.getItem('access_nivel');
  const authenticated = localStorage.getItem('token');
  let to_splited = to.path.toString().split('/')
  to_splited = to_splited.filter(part => part !== '')

  if (
    !authenticated &&
    (
      !(['/login'].includes(to.path.toString())) &&
      !(['public'].includes(to_splited[0]))
    )
  ) {
    next('/login');

    return true;
  }

  if (to.meta.access_nivel) {
    if (!to.meta.access_nivel.includes(access_nivel)) {
      next('/');
    }
  }

  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
