<template>
  <div>
    <div class="main-content" v-if="loaded">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">              
              <div class="row">
                <div class="col-md-2">
                  <label>Classificador</label>
                  <input type="text" class="form-control" v-model="classificator.audit.professional.name" disabled>
                </div>
                <div class="col-md-2">
                  <label>Período</label>
                  <input type="text" class="form-control" v-model="classificator.audit.period" disabled>
                </div>
                <div class="col-md-2">
                  <label>Início</label>
                  <input type="datetime-local" v-model="classificator.audit.start_at" class="form-control" disabled>
                </div>
                <div class="col-md-2">
                  <label>Fim</label>
                  <input type="datetime-local" v-model="classificator.audit.end_at" class="form-control" disabled>
                </div>
                
              </div> 
            </div> 
          </div>
          <div class="card" v-if="section == 1">
            <div class="card-body">                
                <div class="row" v-if="classificator.itens.length > 0">
                    <div class="col-md-12">
                        <div class="table-responsive-sm">
                        <table
                            role="table"
                            aria-busy="false"
                            aria-colcount="4"
                            class="table b-table"
                        >
                            <thead role="rowgroup" class="">
                            <tr role="row" class="">
                                <th
                                role="columnheader"
                                scope="col"
                                aria-colindex="1"
                                class=""
                                >
                                    <div>ID</div>
                                </th>
                                <th
                                role="columnheader"
                                scope="col"
                                aria-colindex="2"
                                class=""
                                >
                                    <div>Nome</div>
                                </th>
                                <th
                                role="columnheader"
                                scope="col"
                                aria-colindex="3"
                                class=""
                                >
                                    <div>Fluxograma</div>
                                </th>                            
                                <th
                                role="columnheader"
                                scope="col"
                                aria-colindex="4"
                                class=""
                                >
                                    <div>Início da Triagem</div>
                                </th>
                                <th
                                role="columnheader"
                                scope="col"
                                aria-colindex="4"
                                class=""
                                >
                                    <div>Divergência</div>
                                </th>
                                <th
                                role="columnheader"
                                scope="col"
                                aria-colindex="4"
                                class=""
                                >
                                    <div>Último Resultado</div>
                                </th>
                                <th
                                role="columnheader"
                                scope="col"
                                aria-colindex="4"
                                class=""
                                >
                                    <div>Qtd Auditorias</div>
                                </th>
                                <th
                                role="columnheader"
                                scope="col"
                                aria-colindex="4"
                                class=""
                                >
                                    <div>Ações</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody role="rowgroup">
                            <tr
                                role="row"
                                class=""
                                v-for="item in classificator.itens"
                                :key="item.id"
                            >
                                <td aria-colindex="1" role="cell" class="">
                                {{ item.atendimento.id }}
                                </td>
                                
                                <td aria-colindex="2" role="cell" class="">
                                    {{
                                        item.atendimento.paciente
                                        ? item.atendimento.paciente.nome_social
                                            ? item.atendimento.paciente.nome_social
                                            : item.atendimento.paciente.nome_paciente
                                        : "Informação não disponível"
                                    }}
                                </td>
                                <td aria-colindex="3" role="cell" class="">
                                    {{
                                        item.atendimento.flowchart
                                        ? item.atendimento.flowchart.title + " / "
                                        : "BRANCO"
                                    }}
                                    {{
                                        item.atendimento.classificacao == 5
                                        ? "AZUL"
                                        : item.atendimento.flowchart_item
                                        ? item.atendimento.flowchart_item.title
                                        : ""
                                    }}
                                </td>
                                <td aria-colindex="4" role="cell" class="">
                                    {{ item.atendimento.inicio_classificacao_f }}
                                </td>
                                <td aria-colindex="4" role="cell" class="">
                                    <span class="badge badge-success" v-if="!item.divergence">Não</span>
                                    <span class="badge badge-warning" v-else-if="!item.result_consenso">Pendente de Consenso</span>
                                    <span class="badge badge-danger" v-else>Sim</span>
                                </td>
                                <td aria-colindex="4" role="cell" class="" v-if="!item.result_consenso">
                                    <span class="badge badge-warning" v-if="item.results.length == 0">Não auditado</span>
                                    <span class="badge badge-success" v-else-if="item.results[0].result == 1">Correto</span>
                                    <span class="badge badge-danger" v-else-if="item.results[0].result == 2">Incorreto</span>
                                </td>
                                <td aria-colindex="4" role="cell" class="" v-else>
                                    <span class="badge badge-success" v-if="item.result_consenso == 1">Correto</span>
                                    <span class="badge badge-danger" v-else-if="item.result_consenso == 2">Incorreto</span>
                                </td>
                                <td aria-colindex="4" role="cell" class="">
                                    {{ item.qtd_audit }}
                                </td>
                                <td aria-colindex="4" role="cell" class="">
                                    <button
                                        class="btn btn-crud info"
                                        @click="audit(item)"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Auditar"
                                        v-if="item.open_to_audit"
                                    >
                                        <i class="fas fa-check"></i>
                                    </button>
                                    <span class="badge badge-success mb-1" v-if="!item.open_to_audit">Auditado</span>   
                                    <button
                                        class="ml-1 btn btn-crud edit"
                                        @click="view(item)"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Visualizar"
                                    >
                                        <i class="fas fa-eye"></i>
                                    </button>                                 
                                </td>
                            </tr>
                            <tr v-if="classificator.itens.length == 0">
                                <td colspan="8" class="centered mt-2">
                                Nenhum atendimento na fila de triagem.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div
                        class="col-md-6 mx-auto d-flex justify-content-center align-items-center"
                    >
                        <pagination
                        :records="total"
                        :options="options"
                        :per-page="15"
                        @paginate="getEntity"
                        ></pagination>
                    </div>
                </div>       
            </div>
          </div>           
          <div class="row" v-if="section == 2"> 
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card" >
                            <div class="card-header bold">
                                Registro Administrativo
                            </div>
                            <div class="card-body">  
                                <div class="row mt-1">
                                    <div class="col-md-10">Identificação do Paciente</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.identity"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Idade</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.age"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Sexo</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.gender"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Data da Triagem</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.date"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Hora Inicial</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.start_hour"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Hora Final</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.end_hour"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Identificação do Classificador</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.identity_classificator"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card" >
                            <div class="card-header bold">
                                Processo de Classificação de Risco
                            </div>
                            <div class="card-body">  
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro da queixa de apresentação</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.complaint"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro do fluxograma</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.set_flowchart_id"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Conformidade entre situação queixa e fluxograma</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.complaint_compliance"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro do discriminador</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.discriminator"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro da prioridade clínica</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.priority"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Conformidade entre discriminador e prioridade clínica</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.priority_compliance"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>               
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro dos parâmetros</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            v-model="auditing.parameters"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                                               
                                <div class="row mt-1">
                                    <div class="col-md-12">
                                        <span>Observações e justificativa de incorretos:</span><br>
                                        <textarea class="form-control mt-1" cols="30" rows="10" v-model="auditing.observation"></textarea>
                                    </div>
                                </div>                                        
                                <div class="row mt-1">
                                    <div class="col-md-12">
                                        <span>Resultado Final:</span><br>
                                        <select v-model="auditing.result" class="form-control">
                                            <option value=""></option>
                                            <option value="1">Correto</option>
                                            <option value="2">Incorreto</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-2 justify-content-center">
                                    <div class="col-md-12">
                                        <button class="btn btn-primary" @click="saveResult"><i class="fas fa-save"></i> Salvar Resultado</button>
                                        <button class="btn btn-danger ml-1" @click="cancel"><i class="fas fa-times"></i> Cancelar</button>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card" >
                    <div class="card-header bold">
                        Ficha
                    </div>
                    <div class="card-body">  
                      <iframe
                        style="width: 100%; height: 100vh"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        :src="$store.state.url + 'atendimento/impressao/' + auditing.atendimento.id + '/' + user.id">
                        </iframe>  
                    </div>   
                </div>
            </div>
          </div>
          
          <div class="row" v-if="section == 3">
            <div class="col-md-12 mb-1">
                <button class="btn btn-primary ml-1" @click="cancel"><i class="fas fa-arrow-left"></i> Voltar</button>
            </div>
            <div class="col-md-12 centered" v-if="results.length == 0">
                Nenhuma auditoria feita para esta ficha.
            </div>
            <div class="col-md-6" v-for="auditing in results" :key="auditing.id">
                <div class="card" v-if="(auditing.audited_by == $store.state.user.id) || item_selected.divergences > 0">
                    <div class="card-header">
                        <span>Auditoria feita por: <strong>{{ auditing.audited.name }}</strong> em: <strong>{{ auditing.created_at_f }}</strong></span>
                    </div>
                    <div class="card-body">  
                        <div class="card">
                            <div class="card-header bold">
                                Registro Administrativo
                            </div>
                            <div class="card-body">  
                                <div class="row mt-1">
                                    <div class="col-md-10">Identificação do Paciente</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.identity"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Idade</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.age"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Sexo</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.gender"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Data da Triagem</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.date"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Hora Inicial</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.start_hour"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Hora Final</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.end_hour"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Identificação do Classificador</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.identity_classificator"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                            </div>   
                        </div>
                        <div class="card" >
                            <div class="card-header bold">
                                Processo de Classificação de Risco
                            </div>
                            <div class="card-body">  
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro da queixa de apresentação</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.complaint"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro do fluxograma</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.set_flowchart_id"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Conformidade entre situação queixa e fluxograma</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.complaint_compliance"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro do discriminador</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.discriminator"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro da prioridade clínica</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.priority"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                        
                                <div class="row mt-1">
                                    <div class="col-md-10">Conformidade entre discriminador e prioridade clínica</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.priority_compliance"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>               
                                <div class="row mt-1">
                                    <div class="col-md-10">Registro dos parâmetros</div>
                                    <div class="col-md-2">
                                        <toggle-button
                                            disabled
                                            v-model="auditing.parameters"
                                            color="#15488A"
                                            :height="25"
                                        />
                                    </div>
                                </div>                                               
                                <div class="row mt-1">
                                    <div class="col-md-12">
                                        <span>Observações e justificativa de incorretos:</span><br>
                                        <textarea disabled class="form-control mt-1" cols="30" rows="10" v-model="auditing.observation"></textarea>
                                    </div>
                                </div>                                        
                                <div class="row mt-1">
                                    <div class="col-md-12">
                                        <span>Resultado Final:</span><br>
                                        <select v-model="auditing.result" class="form-control" disabled>
                                            <option value=""></option>
                                            <option value="1">Correto</option>
                                            <option value="2">Incorreto</option>
                                        </select>
                                    </div>
                                </div>                                
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-if="item_selected.divergences">
                <div class="card" >
                    <div class="card-header bold">
                        Consenso <span v-if="item_selected.user_consenso"> Registrado por: {{ item_selected.user_consenso_obj.name }} em {{ item_selected.date_consenso }}</span>
                    </div>
                    <div class="card-body">  
                        <div class="row">
                            <div class="col-md-6">
                                <label>Resultado do Consenso</label>                                
                                <select :disabled="item_selected.user_consenso" v-model="item_selected.result_consenso" class="form-control">
                                    <option value=""></option>
                                    <option value="1">Correto</option>
                                    <option value="2">Incorreto</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label>Consenso Final</label>                                
                                <textarea :disabled="item_selected.user_consenso" v-model="item_selected.consenso_final" cols="30" rows="10" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="row" v-if="!item_selected.user_consenso">
                            <div class="col-md-12 align-right">
                                <button class="btn btn-primary" @click="saveConsenso"><i class="fas fa-save"></i> Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card" >
                    <div class="card-header bold">
                        Ficha
                    </div>
                    <div class="card-body">  
                      <iframe
                        style="width: 100%; height: 100vh"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        :src="$store.state.url + 'atendimento/impressao/' + results[0].atendimento_id + '/' + 1">
                        </iframe>  
                    </div>   
                </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      user: {},
      item_selected: {},
      auditing: {
        audit_classificator_id: false,
        identity: false,
        age: false,
        gender: false,
        date: false,
        start_hour: false,
        end_hour: false,
        identity_classificator: false,
        time_classification: false,
        complaint: false,
        flowchart_id: false,
        complaint_compliance: false,
        discriminator: false,
        priority: false,
        priority_compliance: false,
        parameters: false,
        observation: false,
        result: false
      },
      classificator: {},
      loaded: false,
      section: 1,
      users: [],
      periods: [
        {
          id: 1,
          period: 'nov/2024'
        },
        {
          id: 2,
          period: 'out/2024'
        },
        {
          id: 3,
          period: 'set/2024'
        },
        {
          id: 4,
          period: 'ago/2024'
        },
        {
          id: 5,
          period: 'jul/2024'
        },
        {
          id: 6,
          period: 'jun/2024'
        },
        {
          id: 7,
          period: 'mai/2024'
        },
        {
          id: 8,
          period: 'abr/2024'
        },
        {
          id: 9,
          period: 'mar/2024'
        },
        {
          id: 10,
          period: 'fev/2024'
        },
        {
          id: 11,
          period: 'jan/2024'
        },
      ],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    cancel(){
        const self = this;          
        self.section = 1;
        self.auditing = {
            audit_classificator_id: false,
            identity: false,
            age: false,
            gender: false,
            date: false,
            start_hour: false,
            end_hour: false,
            identity_classificator: false,
            time_classification: false,
            complaint: false,
            flowchart_id: false,
            complaint_compliance: false,
            discriminator: false,
            priority: false,
            priority_compliance: false,
            parameters: false,
            observation: false,
            result: false,
        };
        
        let id = self.$route.params.id;
        self.getEntity(id);
    },
    saveConsenso(){
        const self = this;
        const api = self.$store.state.api + "audit/save-consenso";
        
        axios
            .post(api, self.item_selected)
            .then((response) => {                
                self.cancel();

                let id = self.$route.params.id;
                self.getEntity(id);
            })
            .catch((error) => {
                self.$message(null, error.response.data, "error");
            });
    },
    saveResult(){
        const self = this;
        const api = self.$store.state.api + "audit/save-result";
        
        axios
            .post(api, self.auditing)
            .then((response) => {                
                self.cancel();

                let id = self.$route.params.id;
                self.getEntity(id);
            })
            .catch((error) => {
                self.$message(null, error.response.data, "error");
            });
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "audit/get-classificator";

      axios
        .post(api, {id: id})
        .then((response) => {
          self.classificator = response.data;
          self.loaded = true;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    audit(obj) {
      const self = this;
      
      self.auditing = obj;

      self.section = 2;
    },  
    view(obj) {
      const self = this;
      const api = self.$store.state.api + "audit_item_results?audit_item_id=" + obj.id + "&paginated=false&with[]=audited";

      self.item_selected = obj;

      axios
        .get(api)
        .then((response) => {
          self.results = response.data.data;
          self.section = 3;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },    
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }

    setTimeout(function () {
      self.user = self.$store.state.user;
    }, 200);
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
</style>