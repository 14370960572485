<template>
  <div>
    <div class="row">
      <div class="col-md-7 col-lg-8 col-xl-9 col-12">
        <div class="card" :key="classificacaoLabel" :style="classificacaoColor">
          <div class="card-body">
            <div class="row">
              <div
                class="d-flex justify-content-between flex-column col-xl-7 col-21"
              >
                <div class="d-flex justify-content-start">
                  <span
                    class="b-avatar badge-light-danger rounded"
                    :style="
                      userDataFullscreen
                        ? 'width: 104px; height: 104px'
                        : 'width: 40px; height: 40px'
                    "
                    ><span class="b-avatar-img"
                      ><img
                        src="@/assets/images/avatars/default.png"
                        alt="avatar" /></span
                  ></span>
                  <div class="d-flex flex-column ml-1">
                    <div :class="userDataFullscreen ? 'mb-1' : ''">
                      <h4 class="mb-0">
                        <span v-if="!atendimento.paciente.nome_social">{{
                          atendimento.paciente.nome_paciente
                        }}</span>
                        <span v-else>{{
                          atendimento.paciente.nome_social
                        }}</span>
                        <span
                          class="badge badge-warning"
                          v-if="atendimento.flag_reclassificacao"
                          >RECLASSIFICAÇÃO</span
                        >
                      </h4>
                      <span class="card-text">{{ diffIdade }}</span>
                      <br v-if="userDataFullscreen" />
                      <br v-if="userDataFullscreen" />
                      <span
                        class="badge mr-1 fs-14"
                        :class="classificacaoBadge"
                        v-if="userDataFullscreen"
                      >
                        {{ classificacaoLabel }}</span
                      >
                      <span class="card-text mt-2" v-if="userDataFullscreen"
                        ><router-link
                          :to="'/triage/edit/' + atendimento.id"
                          class="btn btn-add"
                        >
                          Editar Informações
                          <i class="fas fa-user-edit"></i></router-link
                      ></span>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center mt-2"
                  v-if="userDataFullscreen"
                >
                  <div class="d-flex align-items-center mr-2">
                    <span class="b-avatar badge-light-primary rounded"
                      ><i class="far fa-clock" style="font-size: 18px"></i
                    ></span>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ tempo_espera_unidade }}
                      </h5>
                      <small>Tempo de espera na unidade</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-12" v-if="userDataFullscreen">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <i class="fas fa-venus-mars mr-75"></i>
                      <span class="font-weight-bold">Sexo</span>
                    </th>
                    <td class="pb-50">
                      {{ atendimento.paciente.sexo_f }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <i class="fas fa-id-card mr-75"></i>
                      <span class="font-weight-bold">CNS</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        atendimento.paciente.cns
                          ? atendimento.paciente.cns
                          : "Não informado"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <i class="fas fa-calendar-alt mr-75"></i>
                      <span class="font-weight-bold">Nascimento</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        atendimento.paciente.data_nascimento
                          ? atendimento.paciente.data_nascimento_f
                          : "Não informado"
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        height="14px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="mr-75 feather feather-phone"
                      >
                        <path
                          d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                        ></path>
                      </svg>
                      <span class="font-weight-bold">Telefone</span>
                    </th>
                    <td>
                      {{
                        atendimento.paciente.telefone
                          ? atendimento.paciente.telefone
                          : "Não informado"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <i class="fas fa-id-card mr-75"></i>
                      <span class="font-weight-bold">Origem</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        atendimento.origin_id
                          ? atendimento.origin.title
                          : "Não informado"
                      }}
                    </td>
                  </tr>
                </table>

                <div class="d-flex align-items-center mt-2">
                  <span class="b-avatar badge-light-success rounded"
                    ><i class="far fa-clock" style="font-size: 18px"></i
                  ></span>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ atendimento.tempo_espera_setor }} min
                    </h5>
                    <small>Tempo de espera neste setor</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 col-xl-3 col-12">
        <div class="card border-primary">
          <div
            class="card-header d-flex justify-content-between align-items-center pt-75 pb-25"
            :class="!userDataFullscreen ? 'mb-2' : ''"
          >
            <h5 class="mb-0" style="font-weight: bold; font-size: 20px">
              {{ atendimento.senha }}
            </h5>
            <h2 style="color: blue; margin-top: 5px; margin-right: -35px">
              <span
                id="timer"
                :class="horas > 0 || minutos > 0 ? 'piscar' : ''"
              ></span>
              <i
                :class="minutos > 0 ? 'piscar' : ''"
                class="fas fa-clock fa-spin ml-1 mr-2"
                style="font-size: 24px; color: blue"
              ></i>
            </h2>

            <small class="text-muted w-100">Senha</small>
          </div>
          <div class="card-body" v-if="userDataFullscreen">
            <ul class="list-unstyled my-1">
              <li>
                <span class="align-middle">
                  <h5 class="mb-0" style="font-weight: bold; font-size: 14px">
                    {{ atendimento.data_entrada_f }}
                  </h5>
                  <small class="text-muted w-100"
                    >Data de entrada na unidade</small
                  >
                </span>
              </li>
              <li class="mt-2" v-if="userDataFullscreen">
                <span class="align-middle">
                  <h5 class="mb-0" style="font-weight: bold; font-size: 14px">
                    {{ atendimento.created_at_f }}
                  </h5>
                  <small class="text-muted w-100"
                    >Data de entrada no setor</small
                  >
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-md-12 centered mb-1 clickable"
        style="margin-top: -25px; font-size: 18px"
        @click="toggleFullscreenUser"
      >
        <i
          :class="
            userDataFullscreen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
          "
        ></i>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card content-crud" style="padding: 15px">
          <div class="row">
            <div class="col-md-1" style="border-right: 1px solid #e4e4e4">
              <div
                class="row pd-item"
                :class="page == 1 ? 'selected' : ''"
                @click="nextPage(1)"
              >
                <div class="col-md-12"><i class="far fa-comment-dots"></i></div>
              </div>
              <div
                class="row pd-item"
                :class="page == 2 ? 'selected' : ''"
                @click="nextPage(2)"
              >
                <div class="col-md-12"><i class="fas fa-stethoscope"></i></div>
              </div>
              <div
                class="row pd-item"
                :class="page == 3 ? 'selected' : ''"
                @click="nextPage(3)"
              >
                <div class="col-md-12"><i class="fas fa-heartbeat"></i></div>
              </div>
              <div
                class="row pd-item"
                :class="page == 4 ? 'selected' : ''"
                @click="nextPage(4)"
              >
                <div class="col-md-12"><i class="fas fa-person-booth"></i></div>
              </div>
            </div>
            <div class="col-md-11">
              <section v-if="page == 1">
                <div class="row">
                  <div class="col-md-12">
                    <h3>Queixa principal</h3>
                    <span
                      class="badge badge-warning"
                      v-if="atendimento.flag_reclassificacao"
                      >RECLASSIFICAÇÃO</span
                    >
                    <span class="ml-2 badge badge-dark" v-if="trauma_maior"
                      >JÁ UTILIZOU O FLUXOGRAMA TRAUMA MAIOR</span
                    >
                  </div>
                  <div class="col-md-12 mt-1">
                    <textarea
                      v-model="atendimento.queixa_principal"
                      class="form-control"
                      id=""
                      cols="20"
                      rows="10"
                      :disabled="atendimento.flag_reclassificacao == 1"
                    ></textarea>
                  </div>
                </div>
              </section>
              <section v-if="page == 3">
                <!-- <div class="row mb-3">
                  <div class="col-md-12">
                    <h3>Antropometria</h3>
                    <span
                      class="badge badge-warning mb-1"
                      v-if="atendimento.flag_reclassificacao"
                      >RECLASSIFICAÇÃO</span
                    >
                  </div>

                  <div class="col-md-2">
                    <label for="">Peso</label>
                    <input
                      type="number"
                      class="form-control"
                      id=""
                      v-model="atendimento.peso"
                    />
                  </div>
                  <div class="col-md-2">
                    <label for="">Altura</label>
                    <input
                      type="text"
                      class="form-control"
                      id=""
                      v-model="atendimento.altura"
                      v-mask="'#.##'"
                    />
                  </div>
                  <div class="col-md-2">
                    <label for="">IMC</label>
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      :value="imc ? imc : ''"
                    />
                  </div>
                  <div class="col-ml-1" style="margin-top: -15px">
                    <table class="tabela-imc">
                      <tr>
                        <th
                          v-if="atendimento.peso && atendimento.altura && imc"
                        >
                          Classificação
                        </th>
                        <th
                          v-if="atendimento.peso && atendimento.altura && imc"
                        >
                          IMC
                        </th>
                      </tr>
                      <tr
                        style="background-color: #ffff00; color: #000000"
                        v-if="
                          imc < 18.5 && atendimento.peso && atendimento.altura
                        "
                      >
                        <td>Abaixo do peso</td>
                        <td>Menor que 18,5</td>
                      </tr>
                      <tr
                        style="background-color: #add8e6; color: #000000"
                        v-if="
                          imc >= 18.5 &&
                          imc <= 24.9 &&
                          atendimento.peso &&
                          atendimento.altura
                        "
                      >
                        <td>Peso normal</td>
                        <td>18,5 - 24,9</td>
                      </tr>
                      <tr
                        style="background-color: #ffa500; color: #000000"
                        v-if="
                          imc >= 25 &&
                          imc <= 29.9 &&
                          atendimento.peso &&
                          atendimento.altura
                        "
                      >
                        <td>Sobrepeso</td>
                        <td>25 - 29,9</td>
                      </tr>
                      <tr
                        style="background-color: #ff4500; color: #000000"
                        v-if="
                          imc >= 30 &&
                          imc <= 34.9 &&
                          atendimento.peso &&
                          atendimento.altura
                        "
                      >
                        <td>Obesidade grau 1</td>
                        <td>30 - 34,9</td>
                      </tr>
                      <tr
                        style="background-color: #ff0000; color: #000000"
                        v-if="
                          imc >= 35 &&
                          imc <= 39.9 &&
                          atendimento.peso &&
                          atendimento.altura
                        "
                      >
                        <td>Obesidade grau 2</td>
                        <td>35 - 39,9</td>
                      </tr>
                      <tr
                        style="background-color: #8b0000; color: #000000"
                        v-if="
                          imc >= 40 && atendimento.peso && atendimento.altura
                        "
                      >
                        <td>Obesidade grau 3</td>
                        <td>Maior ou igual a 40</td>
                      </tr>
                    </table>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-md-12">
                    <h3>Sinais vitais</h3>
                  </div>
                  <div class="col-md-12 mt-1">
                    <div class="row">
                      <div
                        class="col-md-4 bold"
                        v-if="
                          !atendimento.sinais.pa_sistolica == '' &&
                          !atendimento.sinais.pa_diastolica == ''
                        "
                      >
                        Pressão arterial
                      </div>
                      <div
                        class="col-md-2 bold"
                        v-if="!atendimento.sinais.temperatura == ''"
                      >
                        Temperatura
                      </div>
                      <div
                        class="col-md-2 bold"
                        v-if="!atendimento.sinais.fr == ''"
                      >
                        FR
                      </div>
                      <div
                        class="col-md-2 bold"
                        v-if="!atendimento.sinais.fc == ''"
                      >
                        FC
                      </div>
                      <div class="col-md-2 bold"></div>
                    </div>
                    <div class="row">
                      <div
                        class="col-md-2"
                        v-if="!atendimento.sinais.pa_sistolica == ''"
                      >
                        <label for="pa_sistolica">Sist. (mmHg):</label>
                        <input
                          type="number"
                          id="pa_sistolica"
                          v-model.number="atendimento.sinais.pa_sistolica"
                          @input="limitarValor"
                          class="form-control"
                          placeholder="Máximo de 300"
                          disabled
                        />
                      </div>
                      <div
                        class="col-md-2"
                        v-if="!atendimento.sinais.pa_diastolica == ''"
                      >
                        <label for="">Diast. (mmHg)</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="atendimento.sinais.pa_diastolica"
                          @input="limitarValor"
                          placeholder="Máximo de 300"
                          disabled
                        />
                      </div>
                      <div
                        class="col-md-2"
                        v-if="!atendimento.sinais.temperatura == ''"
                      >
                        <label for="">C°</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="atendimento.sinais.temperatura"
                          @input="limitarValor"
                          placeholder="Máximo de 60"
                          disabled
                        />
                      </div>
                      <div class="col-md-2" v-if="!atendimento.sinais.fr == ''">
                        <label for="">(rpm)</label>
                        <input
                          type="number"
                          @input="limitarValor"
                          placeholder="Máximo de 300"
                          class="form-control"
                          v-model="atendimento.sinais.fr"
                          disabled
                        />
                      </div>
                      <div class="col-md-2" v-if="!atendimento.sinais.fc == ''">
                        <label for="">(bpm)</label>
                        <input
                          type="number"
                          @input="limitarValor"
                          placeholder="Máximo de 300"
                          class="form-control"
                          v-model="atendimento.sinais.fc"
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      class="row mt-2"
                      v-if="atendimento.sinais.saturacao !== ''"
                    >
                      <div class="col-md-2">
                        <label for="">Saturação (SpO2)</label>
                        <input
                          type="number"
                          @input="limitarValor"
                          placeholder="Máximo de 100"
                          class="form-control"
                          v-model="atendimento.sinais.saturacao"
                          disabled
                        />
                      </div>
                      <div class="col-md-6" style="margin-top: 30px">
                        <span
                          class="boxSelecionavel"
                          :class="{
                            ativo:
                              atendimento.sinais.saturacao_tipo ===
                              'Ar Atmosférico',
                            disabled: true,
                          }"
                          @click="
                            atendimento.sinais.saturacao_tipo = 'Ar Atmosférico'
                          "
                        >
                          Ar Atmosférico
                        </span>
                        <span
                          class="boxSelecionavel"
                          :class="{
                            ativo:
                              atendimento.sinais.saturacao_tipo ===
                              'Em terapia de O2',
                            disabled: true,
                          }"
                          @click="
                            atendimento.sinais.saturacao_tipo =
                              'Em terapia de O2'
                          "
                        >
                          Em terapia de O2
                        </span>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      v-if="!atendimento.sinais.glicemia == ''"
                    >
                      <div class="col-md-2">
                        <label for="">Glicemia (mg/dL)</label>
                        <input
                          type="number"
                          @input="limitarValor"
                          placeholder="Máximo de 1000"
                          class="form-control"
                          v-model="atendimento.sinais.glicemia"
                          disabled
                        />
                      </div>
                      <div class="col-md-6" style="margin-top: 30px">
                        <span
                          class="boxSelecionavel"
                          :class="{
                            ativo:
                              atendimento.sinais.glicemia_tipo === 'Em Jejum',
                            disabled: true,
                          }"
                          @click="atendimento.sinais.glicemia_tipo = 'Em Jejum'"
                        >
                          Em Jejum
                        </span>
                        <span
                          class="boxSelecionavel"
                          :class="{
                            ativo:
                              atendimento.sinais.glicemia_tipo ===
                              'Pós-prandial',
                            disabled: true,
                          }"
                          @click="
                            atendimento.sinais.glicemia_tipo = 'Pós-prandial'
                          "
                        >
                          Pós-prandial
                        </span>
                      </div>
                    </div>
                    <div
                      class="row mt-2"
                      v-if="glasgowCount != 'Não classificado'"
                    >
                      <div class="col-md-3">
                        <label for="">Escala de Glasgow</label>
                        <input
                          data-toggle="modal"
                          data-target="#glasgowModalCenter"
                          readonly
                          type="text"
                          class="form-control"
                          id=""
                          v-model="glasgowCount"
                          disabled
                        />
                      </div>
                      <div class="col-md-9" style="margin-top: 30px">
                        <span v-html="glasgowLabel"></span>
                      </div>
                    </div>
                    <div
                      class="row mt-3"
                      v-if="
                        atendimento.sinais.intensidade_dor ||
                        atendimento.sinais.intensidade_dor === 0
                      "
                    >
                      <div class="col-md-4 bold" style="margin-left: 185px">
                        Escala de Dor
                      </div>
                    </div>
                    <div
                      class="row mb-2"
                      v-if="
                        atendimento.sinais.intensidade_dor ||
                        atendimento.sinais.intensidade_dor === 0
                      "
                    >
                      <div class="col-md-4 bold" style="margin-left: 210px">
                        De 0 á 10
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        atendimento.sinais.intensidade_dor ||
                        atendimento.sinais.intensidade_dor === 0
                      "
                    >
                      <div class="col-md-5">
                        <div class="dor-escala">
                          <div
                            class="escala-item dor-level-0"
                            :class="
                              atendimento.sinais.intensidade_dor == 0
                                ? 'escala-item-active'
                                : ''
                            "
                          >
                            <div class="level-label">0</div>
                          </div>
                          <div
                            class="escala-item dor-level-1"
                            :class="
                              (atendimento.sinais.tempo_dor == 1
                                ? 'azul '
                                : atendimento.sinais.tempo_dor == 0
                                ? 'verde '
                                : 'verde-azul ') +
                              (atendimento.sinais.intensidade_dor == 1
                                ? 'escala-item-active'
                                : '')
                            "
                          >
                            <div class="level-label">1</div>
                          </div>
                          <div
                            class="escala-item dor-level-2"
                            :class="
                              (atendimento.sinais.tempo_dor == 1
                                ? 'azul '
                                : atendimento.sinais.tempo_dor == 0
                                ? 'verde '
                                : 'verde-azul ') +
                              (atendimento.sinais.intensidade_dor == 2
                                ? 'escala-item-active'
                                : '')
                            "
                          >
                            <div class="level-label">2</div>
                          </div>
                          <div
                            class="escala-item dor-level-3"
                            :class="
                              (atendimento.sinais.tempo_dor == 1
                                ? 'azul '
                                : atendimento.sinais.tempo_dor == 0
                                ? 'verde '
                                : 'verde-azul ') +
                              (atendimento.sinais.intensidade_dor == 3
                                ? 'escala-item-active'
                                : '')
                            "
                          >
                            <div class="level-label">3</div>
                          </div>
                          <div
                            class="escala-item dor-level-4"
                            :class="
                              (atendimento.sinais.tempo_dor == 1
                                ? 'azul '
                                : atendimento.sinais.tempo_dor == 0
                                ? 'verde '
                                : 'verde-azul ') +
                              (atendimento.sinais.intensidade_dor == 4
                                ? 'escala-item-active'
                                : '')
                            "
                          >
                            <div class="level-label">4</div>
                          </div>
                          <div
                            class="escala-item dor-level-5"
                            :class="
                              atendimento.sinais.intensidade_dor == 5
                                ? 'escala-item-active'
                                : ''
                            "
                          >
                            <div class="level-label">5</div>
                          </div>
                          <div
                            class="escala-item dor-level-6"
                            :class="
                              atendimento.sinais.intensidade_dor == 6
                                ? 'escala-item-active'
                                : ''
                            "
                          >
                            <div class="level-label">6</div>
                          </div>
                          <div
                            class="escala-item dor-level-7"
                            :class="
                              atendimento.sinais.intensidade_dor == 7
                                ? 'escala-item-active'
                                : ''
                            "
                          >
                            <div class="level-label">7</div>
                          </div>
                          <div
                            class="escala-item dor-level-8"
                            :class="
                              atendimento.sinais.intensidade_dor == 8
                                ? 'escala-item-active'
                                : ''
                            "
                          >
                            <div class="level-label">8</div>
                          </div>
                          <div
                            class="escala-item dor-level-9"
                            :class="
                              atendimento.sinais.intensidade_dor == 9
                                ? 'escala-item-active'
                                : ''
                            "
                          >
                            <div class="level-label">9</div>
                          </div>
                          <div
                            class="escala-item dor-level-10"
                            :class="
                              atendimento.sinais.intensidade_dor == 10
                                ? 'escala-item-active'
                                : ''
                            "
                          >
                            <div class="level-label">10</div>
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="
                            atendimento.sinais.intensidade_dor > 0 &&
                            atendimento.sinais.intensidade_dor < 5
                          "
                        >
                          <div class="col-md-12 centered">
                            <div class="col-md-12" style="margin-bottom: 30px">
                              <span
                                class="boxSelecionavelDor"
                                :class="
                                  atendimento.sinais.tempo_dor == 0
                                    ? 'ativo'
                                    : ''
                                "
                              >
                                < 7 Dias</span
                              >
                              <span
                                class="boxSelecionavelDor"
                                :class="
                                  atendimento.sinais.tempo_dor == 1
                                    ? 'ativo'
                                    : ''
                                "
                              >
                                >= 7 dias</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1" style="margin-top: -15px">
                        <i
                          class="fas fa-info-circle"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="0 Sem dor
                                  1 até 3
                                  Dor leve
                                  4 até 7
                                  Dor moderada
                                  8 até 10
                                  Dor intensa"
                          style="font-size: 18px; margin-top: 30px"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section v-if="page == 2">
                <div class="row">
                  <div class="col-md-12">
                    <h3>Classificação de Risco</h3>
                    <span
                      class="badge badge-warning"
                      v-if="atendimento.flag_reclassificacao"
                      >RECLASSIFICAÇÃO</span
                    >
                    <span class="ml-2 badge badge-dark" v-if="trauma_maior"
                      >JÁ UTILIZOU O FLUXOGRAMA TRAUMA MAIOR</span
                    >
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label class="bold" style="font-size: 14px"
                      >Selecione um Protocolo</label
                    >
                    <v-select
                      :options="protocols"
                      label="title"
                      v-on:input="getFluxograma"
                      v-model="protocol_selected"
                    ></v-select>
                  </div>
                  <!-- <div class="col-md-4">
                    <label class="bold" style="font-size: 14px"
                      >Selecione uma Especialidade</label
                    >
                    <v-select
                      :options="specialties"
                      label="title"
                      @input="changeSpecialty"
                    ></v-select>
                  </div> -->
                  <div class="col-md-6">
                    <label class="bold" style="font-size: 14px"
                      >Selecione um Fluxograma</label
                    >
                    <v-select
                      :options="flowcharts"
                      label="title"
                      @input="changeFlowchart"
                      :value="flowchart"
                    >
                      <div slot="no-options">
                        Aguarde, carregando lista de fluxogramas...
                      </div></v-select
                    >
                  </div>
                </div>
                <div class="row mt-3" v-if="flowchart_selected.title">
                  <div class="col-md-12 centered bold fs-22">
                    {{ flowchart_selected.title }}
                    <i
                      class="fas fa-question-circle clickable"
                      @click="
                        tooltip_modal(
                          flowchart_selected.notes,
                          1,
                          flowchart_selected.view_more
                        )
                      "
                    ></i>
                  </div>
                  <div class="col-md-12">
                    <section id="color_id_1">
                      <div class="row">
                        <div class="col-md-12 p-15" id="vermelho">
                          <div class="row">
                            <div
                              class="col-md-12 mb-1 bold fs-22"
                              style="
                                color: #000;
                                background-color: #fc0303;
                                padding: 5px 5px 5px 10px;
                                border-radius: 5px;
                              "
                            >
                              Vermelho
                            </div>
                            <div
                              class="col-md-12"
                              v-for="(
                                item, index
                              ) in flowchart_selected.items.filter(
                                (color) => color.color_id == 1
                              )"
                              :key="index"
                            >
                              <div
                                class="row flowchart_item"
                                :class="item.active == 1 ? 'active' : ''"
                              >
                                <div
                                  class="col-10 bold fs-18"
                                  :class="item.active !== 0 ? '' : 'opacity'"
                                >
                                  {{ item.title }}
                                  <i
                                    class="fas fa-question-circle clickable"
                                    @click="
                                      tooltip_modal(
                                        item.discriminator.description
                                      )
                                    "
                                  ></i>
                                </div>
                                <div class="col-2 fs-18">
                                  <div v-if="item.active == 1">
                                    <section
                                      class="row"
                                      v-if="item.discriminator.type == null"
                                    >
                                      <div class="col-md-6">
                                        <button
                                          class="btn fs-18 btn-sim"
                                          @click="selectYes(item)"
                                        >
                                          Sim
                                        </button>
                                      </div>
                                      <div class="col-md-6">
                                        <button
                                          class="btn fs-18 btn-nao"
                                          @click="
                                            changeActiveItem(
                                              index,
                                              item.color_id
                                            )
                                          "
                                        >
                                          Não
                                        </button>
                                      </div>
                                    </section>
                                    <section class="row" v-else>
                                      <div class="col-md-12">
                                        <button
                                          class="btn fs-18 btn-medir"
                                          @click="selectMedir(item, index)"
                                        >
                                          {{
                                            parametros_medidos.includes(
                                              item.discriminator.type
                                            )
                                              ? "Visualizar"
                                              : "Medir"
                                          }}
                                        </button>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Laranja -->
                        <div class="col-md-12 p-15" id="laranja">
                          <div class="row">
                            <div
                              class="col-md-12 mb-1 bold fs-22"
                              style="
                                color: #000;
                                background-color: #fc7b03;
                                padding: 5px 5px 5px 10px;
                                border-radius: 5px;
                              "
                            >
                              Laranja
                            </div>
                            <div
                              class="col-md-12"
                              v-for="(
                                item, index
                              ) in flowchart_selected.items.filter(
                                (color) => color.color_id == 2
                              )"
                              :key="index"
                            >
                              <div
                                class="row flowchart_item"
                                :class="item.active == 1 ? 'active' : ''"
                              >
                                <div
                                  class="col-10 bold fs-18"
                                  :class="item.active !== 0 ? '' : 'opacity'"
                                >
                                  {{ item.title }}
                                  <i
                                    class="fas fa-question-circle clickable"
                                    @click="
                                      tooltip_modal(
                                        item.discriminator.description
                                      )
                                    "
                                  ></i>
                                </div>
                                <div class="col-2 fs-18">
                                  <div v-if="item.active == 1">
                                    <section
                                      class="row"
                                      v-if="item.discriminator.type == null"
                                    >
                                      <div class="col-md-6">
                                        <button
                                          class="btn fs-18 btn-sim"
                                          @click="selectYes(item)"
                                        >
                                          Sim
                                        </button>
                                      </div>
                                      <div class="col-md-6">
                                        <button
                                          class="btn fs-18 btn-nao"
                                          @click="
                                            changeActiveItem(
                                              index,
                                              item.color_id
                                            )
                                          "
                                        >
                                          Não
                                        </button>
                                      </div>
                                    </section>
                                    <section class="row" v-else>
                                      <div class="col-md-12">
                                        <button
                                          class="btn fs-18 btn-medir"
                                          @click="selectMedir(item, index)"
                                        >
                                          {{
                                            parametros_medidos.includes(
                                              item.discriminator.type
                                            )
                                              ? "Visualizar"
                                              : "Medir"
                                          }}
                                        </button>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 p-15" id="amarelo">
                          <div class="row">
                            <div
                              class="col-md-12 mb-1 bold fs-22"
                              style="
                                color: #000;
                                background-color: #f5ed02;
                                padding: 5px 5px 5px 10px;
                                border-radius: 5px;
                              "
                            >
                              Amarelo
                            </div>
                            <div
                              class="col-md-12"
                              v-for="(
                                item, index
                              ) in flowchart_selected.items.filter(
                                (color) => color.color_id == 3
                              )"
                              :key="index"
                            >
                              <div
                                class="row flowchart_item"
                                :class="item.active == 1 ? 'active' : ''"
                              >
                                <div
                                  class="col-10 bold fs-18"
                                  :class="item.active !== 0 ? '' : 'opacity'"
                                >
                                  {{ item.title }}
                                  <i
                                    class="fas fa-question-circle clickable"
                                    @click="
                                      tooltip_modal(
                                        item.discriminator.description
                                      )
                                    "
                                  ></i>
                                </div>
                                <div class="col-2 fs-18">
                                  <div v-if="item.active == 1">
                                    <section
                                      class="row"
                                      v-if="item.discriminator.type == null"
                                    >
                                      <div class="col-md-6">
                                        <button
                                          class="btn fs-18 btn-sim"
                                          @click="selectYes(item)"
                                        >
                                          Sim
                                        </button>
                                      </div>
                                      <div class="col-md-6">
                                        <button
                                          class="btn fs-18 btn-nao"
                                          @click="
                                            changeActiveItem(
                                              index,
                                              item.color_id
                                            )
                                          "
                                        >
                                          Não
                                        </button>
                                      </div>
                                    </section>
                                    <section class="row" v-else>
                                      <div class="col-md-12">
                                        <button
                                          class="btn fs-18 btn-medir"
                                          @click="selectMedir(item, index)"
                                        >
                                          {{
                                            parametros_medidos.includes(
                                              item.discriminator.type
                                            )
                                              ? "Visualizar"
                                              : "Medir"
                                          }}
                                        </button>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-12 p-15"
                          id="verde"
                          v-if="
                            flowchart_selected.items.filter(
                              (color) => color.color_id == 4
                            ).length > 0
                          "
                        >
                          <div class="row">
                            <div
                              class="col-md-12 mb-1 bold fs-22"
                              style="
                                color: #000;
                                background-color: #00923f;
                                padding: 5px 5px 5px 10px;
                                border-radius: 5px;
                              "
                            >
                              Verde
                            </div>
                            <div
                              class="col-md-12"
                              v-for="(
                                item, index
                              ) in flowchart_selected.items.filter(
                                (color) => color.color_id == 4
                              )"
                              :key="index"
                            >
                              <div
                                class="row flowchart_item"
                                :class="item.active == 1 ? 'active' : ''"
                              >
                                <div
                                  class="col-10 bold fs-18"
                                  :class="item.active !== 0 ? '' : 'opacity'"
                                >
                                  {{ item.title }}
                                  <i
                                    class="fas fa-question-circle clickable"
                                    @click="
                                      tooltip_modal(
                                        item.discriminator.description
                                      )
                                    "
                                  ></i>
                                </div>
                                <div class="col-2 fs-18">
                                  <div v-if="item.active == 1">
                                    <section
                                      class="row"
                                      v-if="item.discriminator.type == null"
                                    >
                                      <div class="col-md-6">
                                        <button
                                          class="btn fs-18 btn-sim"
                                          @click="selectYes(item)"
                                        >
                                          Sim
                                        </button>
                                      </div>
                                      <div class="col-md-6">
                                        <button
                                          class="btn fs-18 btn-nao"
                                          @click="
                                            changeActiveItem(
                                              index,
                                              item.color_id
                                            )
                                          "
                                        >
                                          Não
                                        </button>
                                      </div>
                                    </section>
                                    <section class="row" v-else>
                                      <div class="col-md-12">
                                        <button
                                          class="btn fs-18 btn-medir"
                                          @click="selectMedir(item, index)"
                                        >
                                          {{
                                            parametros_medidos.includes(
                                              item.discriminator.type
                                            )
                                              ? "Visualizar"
                                              : "Medir"
                                          }}
                                        </button>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row centered justify-content-center"
                        v-show="flowchart_selected.id == 51"
                      >
                        <div class="col-md-6 escolha_outro">
                          Escolha outro fluxograma.
                        </div>
                      </div>
                      <div
                        class="row"
                        id="azul"
                        v-show="atendimento.classificacao == 5"
                      >
                        <div class="col-md-12 mt-3 mb-3 bold fs-22">Azul</div>
                        <div class="col-md-12">
                          <div
                            class="alert alert-primary"
                            role="alert"
                            style="padding: 35px"
                          >
                            Este atendimento tem classificação na cor AZUL,
                            certifique-se desta classificação e clique em
                            <strong>Próxima Etapa</strong>
                          </div>
                        </div>
                      </div>
                      <div class="row centered mt-3">
                        <div class="col-md-12">
                          <button
                            class="btn btn-back"
                            @click="restartClassification"
                          >
                            <i class="fas fa-sync-alt"></i> Reiniciar
                            Classificação
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="row" v-if="!flowchart_selected.id && !trauma_maior">
                  <div class="col-md-4 mt-5">
                    <button
                      class="btn btn-add"
                      style="height: 30px; width: 155px"
                      @click="setarBranco"
                    >
                      Branco
                    </button>
                  </div>
                </div>
              </section>
              <section v-if="page == 4">
                <!-- <div class="row">
                  <div class="col-md-4">
                    <h3>Outras Informações</h3>
                    <span
                      class="badge badge-warning mb-1"
                      v-if="atendimento.flag_reclassificacao"
                      >RECLASSIFICAÇÃO</span
                    >
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-md-6" v-if="atendimento.classificacao == 6">
                    <label class="bold" style="font-size: 14px"
                      >Razão de Entrada</label
                    >
                    <v-select
                      :options="razoes_entrada"
                      label="title"
                      :reduce="(razoes_entrada) => razoes_entrada.id"
                      v-model="atendimento.razao_entrada_id"
                    ></v-select>
                  </div>
                  <!-- <div class="col-md-12">
                    <label class="bold" style="font-size: 14px"
                      >Necessidades Especiais</label
                    >
                    <v-select
                      :options="necessidades_especiais"
                      label="title"
                      :reduce="
                        (necessidades_especiais) => necessidades_especiais.id
                      "
                      v-model="atendimento.necessidades_especiais"
                    ></v-select>
                  </div> -->
                  <!-- <div class="col-md-12 mt-1">
                    <toggle-button
                      v-model="atendimento.sinais.hipertensao"
                      color="#15488A"
                      :height="25"
                    />
                    Hipertensão
                  </div> -->
                  <!-- <div class="col-md-12 mt-1">
                    <toggle-button
                      v-model="atendimento.sinais.diabetes"
                      color="#15488A"
                      :height="25"
                    />
                    Diabetes
                  </div>
                  <div class="col-md-12 mt-1">
                    <toggle-button
                      v-model="atendimento.sinais.alergias_btn"
                      color="#15488A"
                      :height="25"
                    />
                    Alergias
                    <div class="mt-1" v-if="atendimento.sinais.alergias_btn">
                      <label>Descreva quais:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="atendimento.sinais.alergias"
                      />
                    </div>
                  </div> -->
                  <div class="col-md-12 mt-2">
                    <label class="bold" style="font-size: 14px"
                      >Informações relevantes</label
                    >
                    <textarea
                      class="form-control"
                      v-model="atendimento.observacao"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Encaminhamento</h3>
                      </div>
                    </div>
                    <div
                      class="row row-especialidade"
                      v-for="specialty in specialties"
                      :key="specialty.id"
                    >
                      <div
                        class="col-md-12 bg-setor"
                        :class="
                          atendimento.specialty_id == specialty.id
                            ? 'active'
                            : ''
                        "
                        @click="atendimento.specialty_id = specialty.id"
                      >
                        <div class="row">
                          <div class="col-md-10">
                            {{ specialty.title }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-md-12">
                        <label class="bold" style="font-size: 14px"
                          >Selecione uma Especialidade</label
                        >
                        <v-select
                          :options="specialties"
                          label="title"
                          :reduce="(specialty) => specialty.id"
                          v-model="atendimento.specialty_id"
                        ></v-select>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Setor</h3>
                      </div>
                    </div>
                    <div
                      class="row row-setor"
                      v-for="setor in setores"
                      :key="setor.id"
                    >
                      <div
                        class="col-md-12 bg-setor"
                        :class="
                          atendimento.proximo_setor == setor.id ? 'active' : ''
                        "
                        @click="select_sector(setor)"
                      >
                        <div class="row">
                          <div class="col-md-10">
                            {{ setor.title }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div class="row">
                <div class="col-md-12 mt-2 block text-right">
                  <div class="col-md-12">
                    <div class="block text-right">
                      <button
                        class="btn btn-back mr-1"
                        @click="page--"
                        v-if="page > 1"
                      >
                        <i class="fa fa-arrow-left"></i> Etapa Anterior
                      </button>
                      <button
                        class="btn btn-add"
                        v-if="page < 4"
                        @click="nextPage(null)"
                      >
                        Próxima Etapa
                        <i class="fa fa-arrow-right"></i>
                      </button>
                      <button
                        class="btn btn-add"
                        v-if="page == 4"
                        @click="save"
                      >
                        Salvar
                        <i class="fa fa-save"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Glasgow -->
    <div
      class="modal fade"
      id="glasgowModalCenter"
      tabindex="9999"
      role="dialog"
      aria-labelledby="glasgowModalCenterTitle"
      aria-hidden="true"
      style="z-index: 999999999 !important"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="glasgowModalLongTitle">
              Escala de Glasgow
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-striped" style="width: 100%">
                  <thead>
                    <tr>
                      <th scope="col" colspan="3">Abertura Ocular</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("espontanea") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="espontanea1"
                            v-model="glasgow.espontanea"
                            value="4"
                          />
                          <label class="form-check-label" for="espontanea1">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">4</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ glasgow_label("voz") }}</th>

                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.espontanea"
                            value="3"
                            id="espontanea2"
                          />
                          <label class="form-check-label" for="espontanea2">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">3</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ glasgow_label("dor") }}</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.espontanea"
                            value="2"
                            id="espontanea3"
                          />
                          <label class="form-check-label" for="espontanea3">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">2</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ glasgow_label("nao_abre") }}</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.espontanea"
                            value="1"
                            id="espontanea4"
                          />
                          <label class="form-check-label" for="espontanea4">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" colspan="3">Resposta verbal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("orientada") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.verbal"
                            id="verbal1"
                            value="5"
                          />
                          <label class="form-check-label" for="verbal1">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">5</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("confusa") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.verbal"
                            id="verbal2"
                            value="4"
                          />
                          <label class="form-check-label" for="verbal2">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">4</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("p_inapropriadas") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.verbal"
                            id="verbal3"
                            value="3"
                          />
                          <label class="form-check-label" for="verbal3">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">3</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("p_incompreensivas") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.verbal"
                            id="verbal4"
                            value="2"
                          />
                          <label class="form-check-label" for="verbal4">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">2</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("nao_fala") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.verbal"
                            id="verbal5"
                            value="1"
                          />
                          <label class="form-check-label" for="verbal5">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" colspan="3">Resposta motora</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("a") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.motora"
                            id="motora1"
                            value="6"
                          />
                          <label class="form-check-label" for="motora1">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">6</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("b") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.motora"
                            id="motora2"
                            value="5"
                          />
                          <label class="form-check-label" for="motora2">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">5</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("c") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.motora"
                            id="motora3"
                            value="4"
                          />
                          <label class="form-check-label" for="motora3">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">4</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("d") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.motora"
                            id="motora4"
                            value="3"
                          />
                          <label class="form-check-label" for="motora4">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">3</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("e") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.motora"
                            id="motora5"
                            value="2"
                          />
                          <label class="form-check-label" for="motora5">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">2</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        {{ glasgow_label("f") }}
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="glasgow.motora"
                            id="motora6"
                            value="1"
                          />
                          <label class="form-check-label" for="motora6">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              v-if="verifyApplyModal()"
              @click="verifyModal(data_modal)"
              :disabled="glasgowCount < 3 || glasgowCount == 'Não classificado'"
            >
              Salvar Pontuação
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              v-else
              :disabled="glasgowCount < 3 || glasgowCount == 'Não classificado'"
            >
              Salvar Pontuação
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Resumo -->
    <div
      class="modal fade"
      id="modalResumoCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalResumoCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalResumoLongTitle">Resumo</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mt-1">
              <div class="col-md-4">
                <span
                  class="badge mr-1 fs-14"
                  :class="classificacaoBadge"
                  v-if="userDataFullscreen"
                >
                  {{ classificacaoLabel }}</span
                >
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <label>Queixa</label>
                <textarea
                  v-model="atendimento.queixa_principal"
                  class="form-control"
                  id=""
                  cols="20"
                  rows="4"
                  :disabled="true"
                ></textarea>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-3">
                <label>Protocolo</label>
                <br />
                {{ protocol_selected.title }}
              </div>
              <div class="col-md-3">
                <label>Fluxograma</label>
                <br />
                {{ flowchart_selected.title }}
              </div>
              <div class="col-md-3">
                <label>Discriminador</label>
                <br />
                {{ flowchart_item_selected.title }}
              </div>
              <div class="col-md-3">
                <label>Dor</label>
                <br />
                {{
                  atendimento.sinais.intensidade_dor
                    ? atendimento.sinais.intensidade_dor
                    : "-"
                }}
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-2">
                <label>FR</label>
                <br />
                {{ atendimento.sinais.fr ? atendimento.sinais.fr : "-" }}
              </div>
              <div class="col-md-2">
                <label>FC</label>
                <br />
                {{ atendimento.sinais.fc ? atendimento.sinais.fc : "-" }}
              </div>
              <div class="col-md-2">
                <label>Glicemia (mg/dL)</label>
                <br />
                {{
                  atendimento.sinais.glicemia
                    ? atendimento.sinais.glicemia
                    : "-"
                }}
              </div>
              <div class="col-md-2">
                <label>Temperatura</label>
                <br />
                {{
                  atendimento.sinais.temperatura
                    ? atendimento.sinais.temperatura
                    : "-"
                }}
              </div>
              <div class="col-md-2">
                <label>Saturação (SpO2)</label>
                <br />
                {{
                  atendimento.sinais.saturacao
                    ? atendimento.sinais.saturacao
                    : "-"
                }}
              </div>
              <div class="col-md-2">
                <label>Pressão Arterial</label>
                <br />
                {{
                  atendimento.sinais.pa_sistolica
                    ? atendimento.sinais.pa_sistolica
                    : "-"
                }}
                /
                {{
                  atendimento.sinais.pa_diastolica
                    ? atendimento.sinais.pa_diastolica
                    : "-"
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Escala de Glasgow</label>
                <br />
                <span v-if="glasgowCount > 0" v-html="glasgowLabel"></span>
                <span v-else>-</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="page = 3"
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Glicemia  -->
    <div
      class="modal fade"
      id="modalGlicemia"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalGlicemia"
      aria-hidden="true"
      v-if="data_modal.discriminator"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalResumoLongTitle">Glicemia</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-6">
              <label for="">Glicemia (mg/dL)</label>
              <input
                type="number"
                @input="limitarValor"
                placeholder="Máximo de 1000"
                class="form-control"
                v-model="atendimento.sinais.glicemia"
              />
            </div>
            <!-- <div class="col-md-6" style="margin-top: 30px">
              <span
                class="boxSelecionavel"
                @click="atendimento.sinais.glicemia_tipo = 'Em Jejum'"
                :class="
                  atendimento.sinais.glicemia_tipo == 'Em Jejum' ? 'ativo' : ''
                "
                >Em Jejum</span
              >
              <span
                class="boxSelecionavel"
                @click="atendimento.sinais.glicemia_tipo = 'Pós-prandial'"
                :class="
                  atendimento.sinais.glicemia_tipo == 'Pós-prandial'
                    ? 'ativo'
                    : ''
                "
                >Pós-prandial</span
              >
            </div> -->
            <div
              class="col-md-12"
              style="margin-top: 30px"
              v-if="
                data_modal.discriminator.title.includes('Cetose') ||
                data_modal.discriminator.title.includes('cetose')
              "
            >
              <span
                class="boxSelecionavel"
                @click="
                  atendimento.sinais.glicemia_cetose =
                    'Com sinais de acidose (respiração de Kussmaul - profunda e/ou hálito cetônico)'
                "
                :class="
                  atendimento.sinais.glicemia_cetose ==
                  'Com sinais de acidose (respiração de Kussmaul - profunda e/ou hálito cetônico)'
                    ? 'ativo'
                    : ''
                "
                >Com sinais de acidose (respiração de Kussmaul - profunda e/ou
                hálito cetônico)</span
              >
              <span
                class="boxSelecionavel"
                @click="
                  atendimento.sinais.glicemia_cetose = 'Sem sinais de acidose'
                "
                :class="
                  atendimento.sinais.glicemia_cetose == 'Sem sinais de acidose'
                    ? 'ativo'
                    : ''
                "
                >Sem sinais de acidose</span
              >
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="verifyModal()"
              :disabled="!atendimento.sinais.glicemia"
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Regua Dor  -->
    <div
      class="modal fade"
      id="modalReguaDor"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalReguaDor"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalResumoLongTitle">Escala de dor</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center align-items-center">
              <div class="row mt-3">
                <div
                  class="col-md-12 bold"
                  style="margin-left: 5px; margin-bottom: 10px"
                >
                  Escala de dor de 0 á 10
                </div>
              </div>
              <div class="col-md-12">
                <div
                  class="dor-escala justify-content-center align-items-center"
                >
                  <div
                    class="escala-item dor-level-0"
                    :class="
                      atendimento.sinais.intensidade_dor == 0
                        ? 'escala-item-active'
                        : ''
                    "
                    @click="
                      (atendimento.sinais.intensidade_dor = 0),
                        (atendimento.sinais.tempo_dor = null)
                    "
                  >
                    <div class="level-label">0</div>
                  </div>
                  <div
                    class="escala-item dor-level-1"
                    :class="
                      (atendimento.sinais.tempo_dor == 1
                        ? 'azul '
                        : atendimento.sinais.tempo_dor == 0
                        ? 'verde '
                        : 'verde-azul ') +
                      (atendimento.sinais.intensidade_dor == 1
                        ? 'escala-item-active'
                        : '')
                    "
                    @click="atendimento.sinais.intensidade_dor = 1"
                  >
                    <div class="level-label">1</div>
                  </div>
                  <div
                    class="escala-item dor-level-2"
                    :class="
                      (atendimento.sinais.tempo_dor == 1
                        ? 'azul '
                        : atendimento.sinais.tempo_dor == 0
                        ? 'verde '
                        : 'verde-azul ') +
                      (atendimento.sinais.intensidade_dor == 2
                        ? 'escala-item-active'
                        : '')
                    "
                    @click="atendimento.sinais.intensidade_dor = 2"
                  >
                    <div class="level-label">2</div>
                  </div>
                  <div
                    class="escala-item dor-level-3"
                    :class="
                      (atendimento.sinais.tempo_dor == 1
                        ? 'azul '
                        : atendimento.sinais.tempo_dor == 0
                        ? 'verde '
                        : 'verde-azul ') +
                      (atendimento.sinais.intensidade_dor == 3
                        ? 'escala-item-active'
                        : '')
                    "
                    @click="atendimento.sinais.intensidade_dor = 3"
                  >
                    <div class="level-label">3</div>
                  </div>
                  <div
                    class="escala-item dor-level-4"
                    :class="
                      (atendimento.sinais.tempo_dor == 1
                        ? 'azul '
                        : atendimento.sinais.tempo_dor == 0
                        ? 'verde '
                        : 'verde-azul ') +
                      (atendimento.sinais.intensidade_dor == 4
                        ? 'escala-item-active'
                        : '')
                    "
                    @click="atendimento.sinais.intensidade_dor = 4"
                  >
                    <div class="level-label">4</div>
                  </div>
                  <div
                    class="escala-item dor-level-5"
                    :class="
                      atendimento.sinais.intensidade_dor == 5
                        ? 'escala-item-active'
                        : ''
                    "
                    @click="
                      (atendimento.sinais.intensidade_dor = 5),
                        (atendimento.sinais.tempo_dor = null)
                    "
                  >
                    <div class="level-label">5</div>
                  </div>
                  <div
                    class="escala-item dor-level-6"
                    :class="
                      atendimento.sinais.intensidade_dor == 6
                        ? 'escala-item-active'
                        : ''
                    "
                    @click="
                      (atendimento.sinais.intensidade_dor = 6),
                        (atendimento.sinais.tempo_dor = null)
                    "
                  >
                    <div class="level-label">6</div>
                  </div>
                  <div
                    class="escala-item dor-level-7"
                    :class="
                      atendimento.sinais.intensidade_dor == 7
                        ? 'escala-item-active'
                        : ''
                    "
                    @click="
                      (atendimento.sinais.intensidade_dor = 7),
                        (atendimento.sinais.tempo_dor = null)
                    "
                  >
                    <div class="level-label">7</div>
                  </div>
                  <div
                    class="escala-item dor-level-8"
                    :class="
                      atendimento.sinais.intensidade_dor == 8
                        ? 'escala-item-active'
                        : ''
                    "
                    @click="
                      (atendimento.sinais.intensidade_dor = 8),
                        (atendimento.sinais.tempo_dor = null)
                    "
                  >
                    <div class="level-label">8</div>
                  </div>
                  <div
                    class="escala-item dor-level-9"
                    :class="
                      atendimento.sinais.intensidade_dor == 9
                        ? 'escala-item-active'
                        : ''
                    "
                    @click="
                      (atendimento.sinais.intensidade_dor = 9),
                        (atendimento.sinais.tempo_dor = null)
                    "
                  >
                    <div class="level-label">9</div>
                  </div>
                  <div
                    class="escala-item dor-level-10"
                    :class="
                      atendimento.sinais.intensidade_dor == 10
                        ? 'escala-item-active'
                        : ''
                    "
                    @click="
                      (atendimento.sinais.intensidade_dor = 10),
                        (atendimento.sinais.tempo_dor = null)
                    "
                  >
                    <div class="level-label">10</div>
                  </div>
                </div>
                <div
                  class="dor-escala justify-content-center align-items-center mt-1"
                  v-if="atendimento.paciente.idade_meses <= 168"
                >
                  <div class="escala-item-icone">
                    <!-- 0 -->
                  </div>
                  <div
                    class="escala-item-icone"
                    @click="atendimento.sinais.intensidade_dor = 1"
                  >
                    <img src="@/assets/images/escala-dor/0.png" width="70px" />
                  </div>
                  <div class="escala-item-icone">
                    <!-- 2 -->
                  </div>
                  <div
                    class="escala-item-icone"
                    @click="atendimento.sinais.intensidade_dor = 3"
                  >
                    <img src="@/assets/images/escala-dor/1.png" width="70px" />
                  </div>
                  <div class="escala-item-icone">
                    <!-- 4 -->
                  </div>
                  <div
                    class="escala-item-icone"
                    @click="atendimento.sinais.intensidade_dor = 5"
                  >
                    <img src="@/assets/images/escala-dor/2.png" width="70px" />
                  </div>
                  <div class="escala-item-icone">
                    <!-- 6 -->
                  </div>
                  <div
                    class="escala-item-icone"
                    @click="atendimento.sinais.intensidade_dor = 7"
                  >
                    <img src="@/assets/images/escala-dor/3.png" width="70px" />
                  </div>
                  <div class="escala-item-icone">
                    <!-- 8 -->
                  </div>
                  <div
                    class="escala-item-icone"
                    @click="atendimento.sinais.intensidade_dor = 9"
                  >
                    <img src="@/assets/images/escala-dor/4.png" width="80px" />
                  </div>
                  <div class="escala-item-icone">
                    <!-- 10 -->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-12 centered"
              v-if="
                atendimento.sinais.intensidade_dor > 0 &&
                atendimento.sinais.intensidade_dor < 5
              "
            >
              <div class="col-md-12" style="margin-top: 30px">
                <span
                  class="boxSelecionavelDor"
                  @click="atendimento.sinais.tempo_dor = 0"
                  :class="atendimento.sinais.tempo_dor == 0 ? 'ativo' : ''"
                >
                  < 7 Dias</span
                >
                <span
                  class="boxSelecionavelDor"
                  @click="atendimento.sinais.tempo_dor = 1"
                  :class="atendimento.sinais.tempo_dor == 1 ? 'ativo' : ''"
                >
                  >= 7 dias</span
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="verifyModal()"
              :disabled="
                atendimento.sinais.intensidade_dor > 0 &&
                atendimento.sinais.intensidade_dor < 5 &&
                atendimento.sinais.tempo_dor == null
              "
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Saturacao  -->
    <div
      class="modal fade"
      id="modalSaturacao"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalSaturacao"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalResumoLongTitle">Saturação</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-6">
              <label for="">Saturação (SpO2)</label>
              <input
                type="number"
                @input="limitarValor"
                placeholder="Máximo de 100"
                class="form-control"
                v-model="atendimento.sinais.saturacao"
              />
            </div>
            <div class="col-md-6" style="margin-top: 30px">
              <span
                class="boxSelecionavel"
                @click="atendimento.sinais.saturacao_tipo = 'Ar Atmosférico'"
                :class="
                  atendimento.sinais.saturacao_tipo == 'Ar Atmosférico'
                    ? 'ativo'
                    : ''
                "
                >Ar Atmosférico</span
              >
              <span
                class="boxSelecionavel"
                @click="atendimento.sinais.saturacao_tipo = 'Em terapia de O2'"
                :class="
                  atendimento.sinais.saturacao_tipo == 'Em terapia de O2'
                    ? 'ativo'
                    : ''
                "
                >Em terapia de O2</span
              >
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="verifyModal()"
              :disabled="
                !atendimento.sinais.saturacao ||
                !atendimento.sinais.saturacao_tipo
              "
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Temperatura  -->
    <div
      class="modal fade"
      id="modalTemperatura"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalTemperatura"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalResumoLongTitle">Temperatura</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <label for="">C°</label>
              <input
                type="number"
                class="form-control"
                v-model="atendimento.sinais.temperatura"
                @input="limitarValor"
                placeholder="Máximo de 60"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="verifyModal()"
              :disabled="!atendimento.sinais.temperatura"
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Sepse -->
    <div
      class="modal fade"
      id="modalSepse"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalSepse"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalResumoLongTitle">
              Sepse Possível
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <section v-if="Number(atendimento.paciente.idade_dias) > 5110">
              <div class="row" v-if="sepse_adulto.step == 1">
                <div class="col-md-12">
                  <div class="row mt-2">
                    <div class="col-md-3">
                      <label for="">Escala de Glasgow</label>
                      <input
                        data-toggle="modal"
                        data-target="#glasgowModalCenter"
                        readonly
                        type="text"
                        class="form-control"
                        id=""
                        v-model="glasgowCount"
                      />
                    </div>
                    <div class="col-md-9" style="margin-top: 30px">
                      <span v-html="glasgowLabel"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="sepse_adulto.step == 2">
                <div class="col-md-12 mt-1">
                  <label for="">FR</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="atendimento.sinais.fr"
                    @input="limitarValor"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="row" v-if="sepse_adulto.step == 3">
                <div class="col-md-12 mt-1">
                  <label>Pressão Arterial:</label>
                </div>
                <div class="col-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="atendimento.sinais.pa_sistolica"
                    placeholder="Sistolica"
                  />
                </div>
                <div class="col-1">/</div>
                <div class="col-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="atendimento.sinais.pa_diastolica"
                    placeholder="Diastolica"
                  />
                </div>
              </div>
            </section>
            <!-- Sepse Bebê e Criança -->
            <section
              v-if="
                Number(atendimento.paciente.idade_dias) >= 29 &&
                Number(atendimento.paciente.idade_dias) <= 5110
              "
            >
              <div class="row" v-if="sepse_crianca.step == 1">
                <div class="col-md-12">Pulso anormal por faixa etária?</div>
                <div class="col-md-8">
                  <label for="">FC (bpm)</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="atendimento.sinais.fc"
                    @input="limitarValor"
                    placeholder=""
                  />
                </div>
                <div
                  class="col-md-6"
                  style="margin-top: 10px; margin-bottom: 20px"
                >
                  <span
                    class="boxSelecionavel"
                    @click="atendimento.sinais.pulso_tipo = 'Regular'"
                    :class="
                      atendimento.sinais.pulso_tipo == 'Regular' ? 'ativo' : ''
                    "
                    >Regular</span
                  >
                  <span
                    class="boxSelecionavel"
                    @click="atendimento.sinais.pulso_tipo = 'Irregular'"
                    :class="
                      atendimento.sinais.pulso_tipo == 'Irregular'
                        ? 'ativo'
                        : ''
                    "
                    >Irregular</span
                  >
                </div>
              </div>
              <div class="row" v-if="sepse_crianca.step == 1">
                <div
                  class="col-md-6"
                  style="margin-top: 10px; margin-bottom: 20px"
                  v-if="atendimento.paciente.idade_meses <= 168"
                >
                  <span
                    class="boxSelecionavel"
                    @click="atendimento.sinais.pulso_acordado = 'Dormindo'"
                    :class="
                      atendimento.sinais.pulso_acordado == 'Dormindo'
                        ? 'ativo'
                        : ''
                    "
                    >Dormindo</span
                  >
                  <span
                    class="boxSelecionavel"
                    @click="atendimento.sinais.pulso_acordado = 'Acordado'"
                    :class="
                      atendimento.sinais.pulso_acordado == 'Acordado'
                        ? 'ativo'
                        : ''
                    "
                    >Acordado</span
                  >
                </div>
              </div>
              <div class="row" v-if="sepse_crianca.step == 2">
                <div class="col-md-12">Temperatura timpânica</div>
                <div class="col-md-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model="atendimento.sinais.temperatura"
                    @input="limitarValor"
                    placeholder="Máximo de 60"
                  />
                </div>
              </div>
              <div class="row" v-if="sepse_crianca.step == 3">
                <div class="col-md-12">
                  Alteração no padrão de atividade? (irritabilidade, choro
                  inconsolável, sonolência, pouca interação com os familiares)
                </div>
                <div class="col-md-3 mt-2">
                  <div class="row">
                    <div class="col-md-6">
                      <button
                        class="btn fs-18 btn-sim"
                        @click="(sepse_crianca.ac = 2), verifyModalSepse()"
                      >
                        Sim
                      </button>
                    </div>
                    <div class="col-md-6">
                      <button
                        class="btn fs-18 btn-nao"
                        @click="(sepse_crianca.ac = 1), verifyModalSepse()"
                      >
                        Não
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="sepse_crianca.step == 4">
                <div class="col-md-12">
                  <label for=""
                    >Perfusão Capilar Periférica (em segundos)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    v-model="atendimento.sinais.pcp"
                    @input="limitarValor"
                    placeholder=""
                  />
                </div>
              </div>
            </section>
            <!-- Sepse Neonato -->
            <section v-if="Number(atendimento.paciente.idade_dias) < 29">
              <div class="row" v-if="sepse_neonato.step == 1">
                <div class="col-md-12">
                  Alteração no padrão de atividade? (irritabilidade, choro
                  inconsolável, sonolência, pouca interação com os familiares)
                </div>
                <div class="col-md-3 mt-2">
                  <div class="row">
                    <div class="col-md-6">
                      <button
                        class="btn fs-18 btn-sim"
                        @click="
                          (sepse_neonato.ac = 2), verifyModalSepseNeonato()
                        "
                      >
                        Sim
                      </button>
                    </div>
                    <div class="col-md-6">
                      <button
                        class="btn fs-18 btn-nao"
                        @click="
                          (sepse_neonato.ac = 1), verifyModalSepseNeonato()
                        "
                      >
                        Não
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="sepse_neonato.step == 2">
                <div class="col-md-12">
                  <label for="">Perfusão Capilar Central (em segundos)</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="atendimento.sinais.pcp"
                    @input="limitarValor"
                    placeholder=""
                  />
                </div>
              </div>
            </section>
          </div>
          <div
            class="modal-footer"
            v-if="
              Number(atendimento.paciente.idade_dias) >= 29 &&
              Number(atendimento.paciente.idade_dias) <= 5110
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="verifyModalSepse()"
            >
              Avançar
            </button>
          </div>
          <div
            class="modal-footer"
            v-if="Number(atendimento.paciente.idade_dias) < 29"
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="verifyModalSepseNeonato()"
            >
              Avançar
            </button>
          </div>
          <div
            class="modal-footer"
            v-if="Number(atendimento.paciente.idade_dias) > 5110"
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="verifyModalSepseAdulto()"
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Frequencia Cardiaca  -->
    <div
      class="modal fade"
      id="modalFrequenciaCardiaca"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalFrequenciaCardiaca"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalResumoLongTitle">
              Frequência Cardíaca
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-8">
              <label for="">FC (bpm)</label>
              <input
                type="number"
                class="form-control"
                v-model="atendimento.sinais.fc"
                @input="limitarValor"
                placeholder=""
              />
            </div>
            <div class="col-md-6" style="margin-top: 10px; margin-bottom: 20px">
              <span
                class="boxSelecionavel"
                @click="atendimento.sinais.pulso_tipo = 'Regular'"
                :class="
                  atendimento.sinais.pulso_tipo == 'Regular' ? 'ativo' : ''
                "
                >Regular</span
              >
              <span
                class="boxSelecionavel"
                @click="atendimento.sinais.pulso_tipo = 'Irregular'"
                :class="
                  atendimento.sinais.pulso_tipo == 'Irregular' ? 'ativo' : ''
                "
                >Irregular</span
              >
            </div>
            <div
              class="col-md-6"
              style="margin-top: 10px; margin-bottom: 20px"
              v-if="atendimento.paciente.idade_meses <= 168"
            >
              <span
                class="boxSelecionavel"
                @click="atendimento.sinais.pulso_acordado = 'Dormindo'"
                :class="
                  atendimento.sinais.pulso_acordado == 'Dormindo' ? 'ativo' : ''
                "
                >Dormindo</span
              >
              <span
                class="boxSelecionavel"
                @click="atendimento.sinais.pulso_acordado = 'Acordado'"
                :class="
                  atendimento.sinais.pulso_acordado == 'Acordado' ? 'ativo' : ''
                "
                >Acordado</span
              >
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="verifyModal()"
              :disabled="
                !atendimento.sinais.pulso_tipo ||
                !atendimento.sinais.fc ||
                !atendimento.sinais.pulso_acordado
              "
              v-if="atendimento.paciente.idade_meses <= 168"
            >
              Avançar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="verifyModal()"
              :disabled="
                !atendimento.sinais.pulso_tipo || !atendimento.sinais.fc
              "
              v-else
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Pressão Arterial -->
    <div
      class="modal fade"
      id="modalPA"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalPA"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalResumoLongTitle">
              Pressão Arterial
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body row">
            <div class="col-md-12 mt-1">
              <label>Pressão Arterial:</label>
            </div>
            <div class="col-3">
              <input
                type="text"
                class="form-control"
                v-model="atendimento.sinais.pa_sistolica"
                placeholder="Sistolica"
              />
            </div>
            <div class="col-1">/</div>
            <div class="col-3">
              <input
                type="text"
                class="form-control"
                v-model="atendimento.sinais.pa_diastolica"
                placeholder="Diastolica"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="verifyModal()"
              :disabled="
                !atendimento.sinais.pa_sistolica ||
                !atendimento.sinais.pa_diastolica
              "
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Dor ou Coceira -->
    <div
      class="modal fade"
      id="modalDorCoceira"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalDorCoceira"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalResumoLongTitle">
              Dor ou Coceira
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body row">
            <div class="col-md-12" v-if="dor_coceira.step == 1">
              <div class="row justify-content-center align-items-center">
                <div class="row mt-3">
                  <div
                    class="col-md-12 bold"
                    style="margin-left: 5px; margin-bottom: 10px"
                  >
                    Escala de dor de 0 á 10
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    class="dor-escala justify-content-center align-items-center"
                  >
                    <div
                      class="escala-item dor-level-0"
                      :class="
                        atendimento.sinais.intensidade_dor == 0
                          ? 'escala-item-active'
                          : ''
                      "
                      @click="
                        (atendimento.sinais.intensidade_dor = 0),
                          (atendimento.sinais.tempo_dor = null)
                      "
                    >
                      <div class="level-label">0</div>
                    </div>
                    <div
                      class="escala-item dor-level-1"
                      :class="
                        (atendimento.sinais.tempo_dor == 1
                          ? 'azul '
                          : atendimento.sinais.tempo_dor == 0
                          ? 'verde '
                          : 'verde-azul ') +
                        (atendimento.sinais.intensidade_dor == 1
                          ? 'escala-item-active'
                          : '')
                      "
                      @click="atendimento.sinais.intensidade_dor = 1"
                    >
                      <div class="level-label">1</div>
                    </div>
                    <div
                      class="escala-item dor-level-2"
                      :class="
                        (atendimento.sinais.tempo_dor == 1
                          ? 'azul '
                          : atendimento.sinais.tempo_dor == 0
                          ? 'verde '
                          : 'verde-azul ') +
                        (atendimento.sinais.intensidade_dor == 2
                          ? 'escala-item-active'
                          : '')
                      "
                      @click="atendimento.sinais.intensidade_dor = 2"
                    >
                      <div class="level-label">2</div>
                    </div>
                    <div
                      class="escala-item dor-level-3"
                      :class="
                        (atendimento.sinais.tempo_dor == 1
                          ? 'azul '
                          : atendimento.sinais.tempo_dor == 0
                          ? 'verde '
                          : 'verde-azul ') +
                        (atendimento.sinais.intensidade_dor == 3
                          ? 'escala-item-active'
                          : '')
                      "
                      @click="atendimento.sinais.intensidade_dor = 3"
                    >
                      <div class="level-label">3</div>
                    </div>
                    <div
                      class="escala-item dor-level-4"
                      :class="
                        (atendimento.sinais.tempo_dor == 1
                          ? 'azul '
                          : atendimento.sinais.tempo_dor == 0
                          ? 'verde '
                          : 'verde-azul ') +
                        (atendimento.sinais.intensidade_dor == 4
                          ? 'escala-item-active'
                          : '')
                      "
                      @click="atendimento.sinais.intensidade_dor = 4"
                    >
                      <div class="level-label">4</div>
                    </div>
                    <div
                      class="escala-item dor-level-5"
                      :class="
                        atendimento.sinais.intensidade_dor == 5
                          ? 'escala-item-active'
                          : ''
                      "
                      @click="
                        (atendimento.sinais.intensidade_dor = 5),
                          (atendimento.sinais.tempo_dor = null)
                      "
                    >
                      <div class="level-label">5</div>
                    </div>
                    <div
                      class="escala-item dor-level-6"
                      :class="
                        atendimento.sinais.intensidade_dor == 6
                          ? 'escala-item-active'
                          : ''
                      "
                      @click="
                        (atendimento.sinais.intensidade_dor = 6),
                          (atendimento.sinais.tempo_dor = null)
                      "
                    >
                      <div class="level-label">6</div>
                    </div>
                    <div
                      class="escala-item dor-level-7"
                      :class="
                        atendimento.sinais.intensidade_dor == 7
                          ? 'escala-item-active'
                          : ''
                      "
                      @click="
                        (atendimento.sinais.intensidade_dor = 7),
                          (atendimento.sinais.tempo_dor = null)
                      "
                    >
                      <div class="level-label">7</div>
                    </div>
                    <div
                      class="escala-item dor-level-8"
                      :class="
                        atendimento.sinais.intensidade_dor == 8
                          ? 'escala-item-active'
                          : ''
                      "
                      @click="
                        (atendimento.sinais.intensidade_dor = 8),
                          (atendimento.sinais.tempo_dor = null)
                      "
                    >
                      <div class="level-label">8</div>
                    </div>
                    <div
                      class="escala-item dor-level-9"
                      :class="
                        atendimento.sinais.intensidade_dor == 9
                          ? 'escala-item-active'
                          : ''
                      "
                      @click="
                        (atendimento.sinais.intensidade_dor = 9),
                          (atendimento.sinais.tempo_dor = null)
                      "
                    >
                      <div class="level-label">9</div>
                    </div>
                    <div
                      class="escala-item dor-level-10"
                      :class="
                        atendimento.sinais.intensidade_dor == 10
                          ? 'escala-item-active'
                          : ''
                      "
                      @click="
                        (atendimento.sinais.intensidade_dor = 10),
                          (atendimento.sinais.tempo_dor = null)
                      "
                    >
                      <div class="level-label">10</div>
                    </div>
                  </div>
                  <div
                    class="dor-escala justify-content-center align-items-center mt-1"
                    v-if="atendimento.paciente.idade_meses <= 168"
                  >
                    <div class="escala-item-icone">
                      <!-- 0 -->
                    </div>
                    <div
                      class="escala-item-icone"
                      @click="atendimento.sinais.intensidade_dor = 1"
                    >
                      <img
                        src="@/assets/images/escala-dor/0.png"
                        width="70px"
                      />
                    </div>
                    <div class="escala-item-icone">
                      <!-- 2 -->
                    </div>
                    <div
                      class="escala-item-icone"
                      @click="atendimento.sinais.intensidade_dor = 3"
                    >
                      <img
                        src="@/assets/images/escala-dor/1.png"
                        width="70px"
                      />
                    </div>
                    <div class="escala-item-icone">
                      <!-- 4 -->
                    </div>
                    <div
                      class="escala-item-icone"
                      @click="atendimento.sinais.intensidade_dor = 5"
                    >
                      <img
                        src="@/assets/images/escala-dor/2.png"
                        width="70px"
                      />
                    </div>
                    <div class="escala-item-icone">
                      <!-- 6 -->
                    </div>
                    <div
                      class="escala-item-icone"
                      @click="atendimento.sinais.intensidade_dor = 7"
                    >
                      <img
                        src="@/assets/images/escala-dor/3.png"
                        width="70px"
                      />
                    </div>
                    <div class="escala-item-icone">
                      <!-- 8 -->
                    </div>
                    <div
                      class="escala-item-icone"
                      @click="atendimento.sinais.intensidade_dor = 9"
                    >
                      <img
                        src="@/assets/images/escala-dor/4.png"
                        width="80px"
                      />
                    </div>
                    <div class="escala-item-icone">
                      <!-- 10 -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 centered"
                v-if="
                  atendimento.sinais.intensidade_dor > 0 &&
                  atendimento.sinais.intensidade_dor < 5
                "
              >
                <div class="col-md-12" style="margin-top: 30px">
                  <span
                    class="boxSelecionavelDor"
                    @click="atendimento.sinais.tempo_dor = 0"
                    :class="atendimento.sinais.tempo_dor == 0 ? 'ativo' : ''"
                  >
                    < 7 Dias</span
                  >
                  <span
                    class="boxSelecionavelDor"
                    @click="atendimento.sinais.tempo_dor = 1"
                    :class="atendimento.sinais.tempo_dor == 1 ? 'ativo' : ''"
                  >
                    >= 7 dias</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-12" v-if="dor_coceira.step == 2">
              <div class="col-md-12">
                <p class="capitalize-first-letter">
                  {{ data_modal.discriminator.title.split("Dor ou")[1] }}?
                </p>
              </div>
              <div class="col-md-3 mt-2">
                <div class="row">
                  <div class="col-md-6">
                    <button
                      class="btn fs-18 btn-sim"
                      @click="
                        (dor_coceira.coceira = 2), verifyModalDorCoceira()
                      "
                    >
                      Sim
                    </button>
                  </div>
                  <div class="col-md-6">
                    <button
                      class="btn fs-18 btn-nao"
                      @click="
                        (dor_coceira.coceira = 1), verifyModalDorCoceira()
                      "
                    >
                      Não
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="verifyModalDorCoceira()"
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Tooltip -->
    <div
      class="modal fade"
      id="modalTooltip"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalTooltip"
      aria-hidden="true"
      v-if="load_modal_info"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="modalResumoLongTitle"
              v-if="tooltip_type == 1"
            >
              Notas do Fluxograma e Veja também
            </h5>
            <h5 class="modal-title" id="modalResumoLongTitle" v-else>
              Explicação do Discriminador
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body row" v-if="tooltip_type == 1">
            <div class="col-md-6 mt-1">
              <strong>Notas do Fluxograma</strong>
            </div>
            <div class="col-md-6 mt-1">
              <strong>Veja também</strong>
            </div>
            <div class="col-md-6 mt-1">
              {{ tooltip_data[0] }}
            </div>
            <div class="col-md-6 mt-1" v-html="tooltip_data[1]"></div>
          </div>
          <div class="modal-body row" v-else>
            <div class="col-md-12 mt-1 mb-2" v-html="tooltip_data[0]"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      load_modal_info: false,
      trauma_maior: false,
      last_signals: {},
      last_discriminator: null,
      sepse_crianca: {
        step: 1,
        pulso: null,
        pulso_tipo: null,
        pulso_anormal: false,
        pulso_acordado: false,
        temperatura: null,
        temperatura_anormal: false,
        ac: null,
        ac_anormal: false,
        pc: null,
        pc_anormal: false,
      },
      sepse_neonato: {
        step: 1,
        ac: null,
        ac_anormal: false,
        pc: null,
        pc_anormal: false,
      },
      sepse_adulto: {
        step: 1,
      },
      dor_coceira: {
        step: 1,
        coceira: null,
      },
      tooltip_data: [],
      tooltip_type: "",
      interval: "",
      userDataFullscreen: true,
      page: 1,
      index_medir: 0,
      triage: {},
      user: {},
      data_modal: {},
      data_submodal: {},
      flowchart: {},
      flowchart_item_selected: {},
      flowcharts: [],
      protocols: [],
      setores: [],
      parametros_medidos: [],
      razoes_entrada: [],
      necessidades_especiais: [],
      flowchart_selected: {},
      specialty_selected: {
        id: "",
      },
      original_flowchart_selected: {},
      protocol_selected: {
        id: "",
      },
      atendimento: {
        sinais: {
          pulso_tipo: "",
          saturacao_tipo: "",
          glicemia_tipo: "",
          glicemia_cetose: "",
          classificacao: 1,
          pa_sistolica: 0,
          pa_diastolica: 0,
          temperatura: 0,
          saturacao: 0,
          glicemia: 0,
          alergias_btn: 0,
          intensidade_dor: 0,
          tempo_dor: 0,
        },
        paciente: {
          nome_social: "",
        },
      },
      valorMaximo: 300,
      valorMaximoTempetura: 60,
      valorMaximoSaturacao: 100,
      valorMaximoGlicemia: 3000,
      specialties: [],
      tempo: 0,
      minutos: 0,
      segundos: 0,
      horas: 0,
      horaImprimivel: "",
      glasgow: {},
    };
  },
  components: {},
  methods: {
    glasgow_label(obj) {
      const self = this;

      switch (obj) {
        case "espontanea":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Espontânea";
          } else {
            return "Olhos abertos espontâneamente";
          }
          break;
        case "voz":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Ao comando verbal";
          } else {
            return "Apenas abre os olhos quando ouve falar (ordem verbal)";
          }
          break;
        case "dor":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Somente a estímulos dolorosos";
          } else {
            return "Apenas abre os olhos quando sente dor (estímulo doloroso)";
          }
          break;
        case "nao_abre":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Sem resposta";
          } else {
            return "Não abre os olhos";
          }
          break;
        case "orientada":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Resmunga e balbucia";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Orientada, adequada";
          } else {
            return "Discurso coerente e orientado";
          }
          break;

        case "confusa":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Choro irritado";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Confusa";
          } else {
            return "Discurso confuso";
          }
          break;

        case "p_inapropriadas":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Chora em resposta à dor";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Palavras inadequadas";
          } else {
            return "Discurso inapropriado";
          }
          break;

        case "p_incompreensivas":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Geme em resposta à dor";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Sons ininteligíveis";
          } else {
            return "Discurso incompreensivel ou Sons incompreensíveis";
          }
          break;

        case "nao_fala":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Sem resposta";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Sem resposta";
          } else {
            return "Não fala";
          }
          break;

        case "a":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Mexe-se espontânea e deliberadamente";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Obedece a ordens";
          } else {
            return "Cumpre ordens";
          }
          break;

        case "b":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Reflexo de retirada em resposta ao toque";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Localiza estímulo doloroso";
          } else {
            return "Não cumpre ordens mas localiza a dor";
          }
          break;

        case "c":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Reflexo de retirada em resposta à dor";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Reflexo de retirada em resposta à dor";
          } else {
            return "Flexão anormal do(s) membro(s) à dor (movimento de fuga)";
          }
          break;

        case "d":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Postura de flexão anormal em resposta à dor";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Flexão em resposta à dor";
          } else {
            return "Flexão do(s) membro(s) (descorticação)";
          }
          break;

        case "e":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Postura de extensão anormal em resposta à dor";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Extensão em resposta à dor";
          } else {
            return "Extensão do(s) membro(s) (descerebração)";
          }
          break;

        case "f":
          if (
            self.atendimento.paciente.idade_dias > 0 &&
            self.atendimento.paciente.idade_dias <= 730
          ) {
            return "Sem resposta";
          } else if (
            self.atendimento.paciente.idade_dias > 730 &&
            self.atendimento.paciente.idade_dias <= 5110
          ) {
            return "Sem resposta";
          } else {
            return "Não mexe os membros";
          }
          break;

        default:
          break;
      }
    },
    verifyApplyModal() {
      const self = this;

      if (!self.data_modal.discriminator) {
        return true;
      }

      if (self.data_modal.discriminator.type != "Z") {
        return true;
      } else {
        return false;
      }
    },
    tooltip_modal(data, type, view_more) {
      const self = this;

      self.load_modal_info = false;

      setTimeout(() => {
        if (type == 1) {
          self.tooltip_data[0] = data;
          self.tooltip_data[1] = view_more;
        } else {
          self.tooltip_data[0] = data;
          console.log("Teste");
        }
      }, 500);

      setTimeout(() => {
        self.load_modal_info = true;
      }, 500);

      this.tooltip_type = type;

      setTimeout(() => {
        $("#modalTooltip").modal("toggle");
      }, 500);
    },
    limitarValor() {
      console.log("limitando", this.atendimento.sinais.saturacao);
      if (this.atendimento.sinais.pa_sistolica > this.valorMaximo) {
        this.atendimento.sinais.pa_sistolica = this.valorMaximo;
      } else if (this.atendimento.sinais.pa_sistolica < 0) {
        this.atendimento.sinais.pa_sistolica = "";
      }

      if (this.atendimento.sinais.pa_diastolica > this.valorMaximo) {
        this.atendimento.sinais.pa_diastolica = this.valorMaximo;
      } else if (this.atendimento.sinais.pa_diastolica < 0) {
        this.atendimento.sinais.pa_diastolica = "";
      }

      if (this.atendimento.sinais.temperatura > this.valorMaximoTempetura) {
        this.atendimento.sinais.temperatura = this.valorMaximoTempetura;
      } else if (this.atendimento.sinais.temperatura < 0) {
        this.atendimento.sinais.temperatura = "";
      }

      if (this.atendimento.sinais.fr > this.valorMaximo) {
        this.atendimento.sinais.fr = this.valorMaximo;
      } else if (this.atendimento.sinais.fr < 0) {
        this.atendimento.sinais.fr = "";
      }

      if (this.atendimento.sinais.fc > this.valorMaximo) {
        this.atendimento.sinais.fc = this.valorMaximo;
      } else if (this.atendimento.sinais.fc < 0) {
        this.atendimento.sinais.fc = "";
      }

      if (this.sepse_crianca.pulso > this.valorMaximo) {
        this.sepse_crianca.pulso = this.valorMaximo;
      } else if (this.sepse_crianca.pulso < 0) {
        this.sepse_crianca.pulso = "";
      }

      if (this.atendimento.sinais.saturacao > this.valorMaximoSaturacao) {
        this.atendimento.sinais.saturacao = this.valorMaximoSaturacao;
      } else if (this.atendimento.sinais.saturacao < 0) {
        this.atendimento.sinais.saturacao = "";
      }

      if (this.atendimento.sinais.glicemia > this.valorMaximoGlicemia) {
        this.atendimento.sinais.glicemia = this.valorMaximoGlicemia;
      } else if (this.atendimento.sinais.glicemia < 0) {
        this.atendimento.sinais.glicemia = "";
      }
    },
    calculateSliceSizes() {
      // Substitua os valores abaixo pelos seus intervalos de IMC desejados
      const yellowMax = 18.5;
      const blueMax = 25;
      const orangeMax = 30;
      const totalSize = 100; // Tamanho total da pizza em porcentagem

      // Calcule o tamanho das fatias com base nos intervalos de IMC
      this.yellowSliceSize = `${(yellowMax / orangeMax) * totalSize}%`;
      this.blueSliceSize = `${
        ((blueMax - yellowMax) / orangeMax) * totalSize
      }%`;
      this.orangeSliceSize = `${
        ((orangeMax - blueMax) / orangeMax) * totalSize
      }%`;
      this.redSliceSize = `${((100 - orangeMax) / orangeMax) * totalSize}%`;
    },
    changeSpecialty(value) {
      if (value == null) {
        this.specialty_selected = { id: "" };
        this.flowchart_selected = {};
        this.flowchart = {};
        this.original_flowchart_selected = {};
        this.atendimento.classificacao = 1;
        var i = 0;
        this.flowcharts.forEach((element) => {
          element.items.forEach((el) => {
            if (i == 0) {
              el.active = 1;
            } else {
              el.active = 0;
            }
            i++;
          });
        });
      } else {
        this.specialty_selected = value;
        this.flowchart_selected = {};
        this.flowchart = {};
        this.original_flowchart_selected = {};
        this.atendimento.classificacao = 1;
        var i = 0;
        this.flowcharts.forEach((element) => {
          element.items.forEach((el) => {
            if (i == 0) {
              el.active = 1;
            } else {
              el.active = 0;
            }
            i++;
          });
        });

        this.getFluxograma();
      }
    },
    restartClassification() {
      const self = this;

      self.parametros_medidos = [];
      self.glasgow = {};

      self.atendimento.sinais = {
        pulso_tipo: "",
        pulso_acordado: "",
        saturacao_tipo: "",
        glicemia_tipo: "",
        glicemia_cetose: "",
        classificacao: 1,
        pa_sistolica: "",
        pa_diastolica: "",
        temperatura: "",
        saturacao: "",
        glicemia: "",
        alergias_btn: "",
        intensidade_dor: "",
        tempo_dor: null,
      };

      self.atendimento.sinais.glasgow = 0;
      self.atendimento.sinais.glasgow_label = "";

      self.sepse_crianca = {
        step: 1,
        pulso: null,
        pulso_tipo: null,
        pulso_anormal: false,
        temperatura: null,
        temperatura_anormal: false,
        ac: null,
        ac_anormal: false,
        pc: null,
        pc_anormal: false,
      };

      self.sepse_neonato = {
        step: 1,
        ac: null,
        ac_anormal: false,
        pc: null,
        pc_anormal: false,
      };

      self.sepse_adulto = {
        step: 1,
      };

      setTimeout(() => {
        self.scrollToElementWithMargin("vermelho", 100);
      }, 600);

      this.flowchart_selected = JSON.parse(
        JSON.stringify(this.original_flowchart_selected)
      );
      this.atendimento.classificacao = 1;
    },
    changeFlowchart(value) {
      const self = this;
      if (value == null) {
        this.flowchart_selected = {};
        this.flowchart = {};
        this.original_flowchart_selected = {};
        this.atendimento.classificacao = 1;
        this.flowcharts.forEach((element) => {
          var i = 0;
          element.items.forEach((el) => {
            if (i == 0) {
              el.active = 1;
            } else {
              el.active = 0;
            }
            i++;
          });
        });
      } else {
        this.flowcharts.forEach((element) => {
          var i = 0;
          element.items.forEach((el) => {
            if (i == 0) {
              el.active = 1;
            } else {
              el.active = 0;
            }
            i++;
          });
        });
        this.atendimento.classificacao = 1;
        this.flowchart_selected = value;
        this.flowchart = value;
        this.original_flowchart_selected = JSON.parse(JSON.stringify(value));
        setTimeout(function () {
          self.scrollToElementWithMargin("vermelho", 100);
        }, 500);
        setTimeout(function () {
          $(function () {
            $('[data-toggle="tooltip"]').tooltip();
          });
        }, 500);
      }
    },
    select_sector(obj) {
      const objs = obj;
      const self = this;

      self.atendimento.proximo_setor = objs.id;
    },
    imprimirFicha(obj) {
      const self = this;
      let url =
        self.$store.state.url +
        "atendimento/impressao/" +
        obj.id +
        "/" +
        self.user.id;
      window.open(url, "_blank").focus();
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "atendimentos";

      if (!self.atendimento.sinais.alergias_btn) {
        self.atendimento.sinais.alergias = "Nenhuma";
      }

      if (self.atendimento.id) {
        self.atendimento._method = "PUT";
        api += "/" + self.atendimento.id;
        self.atendimento.status = 1;
        self.atendimento.sinais.glasgow = self.glasgowCount;
        self.atendimento.sinais.glasgow_label = self.glasgowLabelTxt;
        self.atendimento.fim_classificacao = moment().format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }

      axios
        .post(api, self.atendimento)
        .then((response) => {
          swal({
            title: "Atendimento finalizado! ",
            text: "Deseja imprimir a ficha de triagem?",
            type: "success",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim, deletar!",
            showLoaderOnConfirm: true,
            buttons: {
              catch: {
                text: "Não",
                value: "cancel",
                className: "btn-danger",
              },
              confirm: {
                text: "Sim, imprimir",
                value: "confirm",
              },
            },
          }).then((value) => {
            switch (value) {
              case "cancel":
                swal(
                  "OK",
                  "Paciente encaminhado para o setor escolhido.",
                  "success"
                );
                self.$router.push("/triage");
                break;

              case "confirm":
                self.imprimirFicha(self.atendimento);
                self.$router.push("/triage");
                break;
            }
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    selectMedir(obj, index) {
      const self = this;

      self.data_modal = obj;
      self.index_medir = index;

      console.log("tipo", obj);
      switch (obj.discriminator.type) {
        case "G":
          $("#glasgowModalCenter").modal("toggle");
          break;
        case "GC":
          $("#modalGlicemia").modal("toggle");
          break;
        case "P":
          $("#modalPA").modal("toggle");
          break;
        case "R":
          $("#modalReguaDor").modal("toggle");
          break;
        case "S":
          $("#modalSaturacao").modal("toggle");
          break;
        case "T":
          $("#modalTemperatura").modal("toggle");
          break;
        case "B":
          $("#modalFrequenciaCardiaca").modal("toggle");
          break;
        case "Z":
          $("#modalSepse").modal("toggle");
          break;
        case "DC":
          $("#modalDorCoceira").modal("toggle");
          break;
      }
    },
    verifyModalDorCoceira() {
      const self = this;

      switch (self.dor_coceira.step) {
        case 1:
          var valores_referencia =
            self.data_modal.discriminator.valor_referencia.split(",");

          if (
            valores_referencia.includes(
              self.atendimento.sinais.intensidade_dor + ""
            )
          ) {
            self.selectYes(self.data_modal);
            $("#modalDorCoceira").modal("toggle");
          } else {
            self.dor_coceira.step = 2;
          }
          break;

        case 2:
          if (self.dor_coceira.coceira == 2) {
            self.selectYes(self.data_modal);
            $("#modalDorCoceira").modal("toggle");
          } else {
            $("#modalDorCoceira").modal("toggle");
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
            self.dor_coceira.step = 1;
          }
          break;

        default:
          break;
      }
    },
    verifyModalSepseAdulto() {
      const self = this;

      switch (self.sepse_adulto.step) {
        case 1:
          if (self.glasgowCount < 15) {
            $("#modalSepse").modal("hide");
            self.selectYes(self.data_modal);
          } else {
            self.sepse_adulto.step = 2;
          }
          break;

        case 2:
          if (Number(self.atendimento.sinais.fr) > 22) {
            $("#modalSepse").modal("hide");
            self.selectYes(self.data_modal);
          } else {
            self.sepse_adulto.step = 3;
          }
          break;

        case 3:
          if (Number(self.atendimento.sinais.pa_sistolica) < 100) {
            $("#modalSepse").modal("hide");
            self.selectYes(self.data_modal);
          } else {
            $("#modalSepse").modal("hide");
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
          break;

        default:
          break;
      }
    },
    verifyModalSepseNeonato() {
      const self = this;

      switch (self.sepse_neonato.step) {
        case 1:
          if (self.sepse_neonato.ac == 2) {
            $("#modalSepse").modal("hide");
            self.sepse_neonato.ac_anormal = true;
            self.selectYes(self.data_modal);
          } else {
            self.sepse_neonato.step = 2;
          }
          break;

        case 2:
          if (self.atendimento.sinais.pcp > 2) {
            $("#modalSepse").modal("hide");
            self.sepse_neonato.pc_anormal = true;
            self.selectYes(self.data_modal);
          } else {
            $("#modalSepse").modal("hide");
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
          break;

        default:
          break;
      }
    },
    verifyModalSepse() {
      const self = this;

      console.log("entrou");

      switch (self.sepse_crianca.step) {
        case 1:
          if (self.atendimento.sinais.pulso_tipo == "") {
            self.$message(
              "Ops",
              "Você precisa informar o tipo de pulso (Regular ou Irregular)",
              "error"
            );
            return;
          }

          //Recem nascido
          if (
            Number(self.atendimento.paciente.idade_dias) >= 0 &&
            Number(self.atendimento.paciente.idade_dias) <= 90
          ) {
            var menor = 85;
            var maior = 205;

            if (self.atendimento.sinais.pulso_acordado == "Dormindo") {
              var menor = 80;
              var maior = 160;
            }

            if (
              self.atendimento.sinais.fc < menor ||
              self.atendimento.sinais.fc > maior
            ) {
              self.sepse_crianca.step = 2;
              self.sepse_crianca.pulso_anormal = true;
            } else {
              self.sepse_crianca.step = 3;
            }
          }

          //Bebê
          if (
            Number(self.atendimento.paciente.idade_dias) >= 91 &&
            Number(self.atendimento.paciente.idade_dias) <= 730
          ) {
            var menor = 100;
            var maior = 190;

            if (self.atendimento.sinais.pulso_acordado == "Dormindo") {
              var menor = 75;
              var maior = 160;
            }

            if (
              self.atendimento.sinais.fc < menor ||
              self.atendimento.sinais.fc > maior
            ) {
              self.sepse_crianca.step = 2;
              self.sepse_crianca.pulso_anormal = true;
            } else {
              self.sepse_crianca.step = 3;
            }
          }

          //2 a 10 anos
          if (
            Number(self.atendimento.paciente.idade_meses) > 730 &&
            Number(self.atendimento.paciente.idade_meses) <= 360
          ) {
            var menor = 60;
            var maior = 140;

            if (self.atendimento.sinais.pulso_acordado == "Dormindo") {
              var menor = 60;
              var maior = 90;
            }

            if (
              self.atendimento.sinais.fc < menor ||
              self.atendimento.sinais.fc > maior
            ) {
              self.sepse_crianca.step = 2;
              self.sepse_crianca.pulso_anormal = true;
            } else {
              self.sepse_crianca.step = 3;
            }
          }

          break;

        case 2:
          if (self.atendimento.sinais.temperatura < "38.5") {
            $("#modalSepse").modal("hide");
            self.selectYes(self.data_modal);
          } else {
            self.sepse_crianca.step = 3;
          }
          break;

        case 3:
          if (self.sepse_crianca.ac == 2) {
            $("#modalSepse").modal("hide");
            self.sepse_crianca.ac_anormal = true;
            self.selectYes(self.data_modal);
          } else {
            self.sepse_crianca.step = 4;
          }
          break;

        case 4:
          if (self.atendimento.sinais.pcp > 2) {
            $("#modalSepse").modal("hide");
            self.sepse_crianca.pc_anormal = true;
            self.selectYes(self.data_modal);
          } else {
            $("#modalSepse").modal("hide");
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
          break;

        default:
          break;
      }
    },
    verifyModal() {
      const self = this;

      console.log("verificando modal", self.data_modal.discriminator.type);

      self.parametros_medidos.push(self.data_modal.discriminator.type);

      if (self.data_modal.discriminator.type == "Z") {
        var errors = 0;

        if (Number(self.atendimento.paciente.idade_sf) > 13) {
          if (Number(self.atendimento.sinais.pa_sistolica) <= 99) {
            errors++;
          }

          if (Number(self.atendimento.sinais.fr) > 22) {
            errors++;
          }
        } else if (Number(self.atendimento.sinais.pcp) < 2) {
          errors++;
        }

        if (errors > 0) {
          self.selectYes(self.data_modal);
        } else {
          self.changeActiveItem(self.index_medir, self.data_modal.color_id);
        }
      }

      if (self.data_modal.discriminator.type == "G") {
        $("#modalSepse").modal("hide");
        var total =
          Number(self.glasgow.verbal) +
          Number(self.glasgow.motora) +
          Number(self.glasgow.espontanea);
        console.log(total);
        if (total < 15) {
          self.selectYes(self.data_modal);
        } else {
          self.changeActiveItem(self.index_medir, self.data_modal.color_id);
        }
      }

      if (self.data_modal.discriminator.type == "B") {
        //Verifica se o pulso ja foi informado
        if (self.atendimento.sinais.pulso_tipo == "") {
          self.$message(
            "Ops",
            "Você precisa informar se a frequência cardíaca está regular ou irregular",
            "error"
          );
          $("#modalFrequenciaCardiaca").modal("toggle");
          return;
        }

        //Se for irregular, independente do valor, ja considera como sim
        if (self.atendimento.sinais.pulso_tipo == "Irregular") {
          self.selectYes(self.data_modal);
          return;
        }

        if (self.atendimento.paciente.idade_meses != null) {
          console.log(self.atendimento.paciente.idade_meses);
          //Recem nascido
          if (
            Number(self.atendimento.paciente.idade_meses) >= 0 &&
            Number(self.atendimento.paciente.idade_meses) < 3
          ) {
            var menor = 85;
            var maior = 205;

            if (self.atendimento.sinais.pulso_acordado == "Dormindo") {
              var menor = 80;
              var maior = 160;
            }

            if (
              self.atendimento.sinais.fc < menor ||
              self.atendimento.sinais.fc > maior
            ) {
              self.selectYes(self.data_modal);
            } else {
              self.changeActiveItem(self.index_medir, self.data_modal.color_id);
            }
          }

          //Bebê
          if (
            Number(self.atendimento.paciente.idade_meses) >= 3 &&
            Number(self.atendimento.paciente.idade_meses) < 24
          ) {
            var menor = 100;
            var maior = 190;

            if (self.atendimento.sinais.pulso_acordado == "Dormindo") {
              var menor = 75;
              var maior = 160;
            }

            if (
              self.atendimento.sinais.fc < menor ||
              self.atendimento.sinais.fc > maior
            ) {
              self.selectYes(self.data_modal);
            } else {
              self.changeActiveItem(self.index_medir, self.data_modal.color_id);
            }
          }

          //2 a 10 anos
          if (
            Number(self.atendimento.paciente.idade_meses) >= 24 &&
            Number(self.atendimento.paciente.idade_meses) < 120
          ) {
            var menor = 60;
            var maior = 140;

            if (self.atendimento.sinais.pulso_acordado == "Dormindo") {
              var menor = 60;
              var maior = 90;
            }

            if (
              self.atendimento.sinais.fc < menor ||
              self.atendimento.sinais.fc > maior
            ) {
              self.selectYes(self.data_modal);
            } else {
              self.changeActiveItem(self.index_medir, self.data_modal.color_id);
            }
          }

          //maior que 10 anos
          if (
            Number(self.atendimento.paciente.idade_meses) >= 120 &&
            Number(self.atendimento.paciente.idade_meses) < 168
          ) {
            var menor = 60;
            var maior = 100;

            if (self.atendimento.sinais.pulso_acordado == "Dormindo") {
              var menor = 50;
              var maior = 90;
            }

            if (
              self.atendimento.sinais.fc < menor ||
              self.atendimento.sinais.fc > maior
            ) {
              self.selectYes(self.data_modal);
            } else {
              self.changeActiveItem(self.index_medir, self.data_modal.color_id);
            }
          }

          if (Number(self.atendimento.paciente.idade_meses) >= 168) {
            var menor = 60;
            var maior = 100;

            if (
              self.atendimento.sinais.fc < menor ||
              self.atendimento.sinais.fc > maior
            ) {
              self.selectYes(self.data_modal);
            } else {
              self.changeActiveItem(self.index_medir, self.data_modal.color_id);
            }
          }
        } else {
          if (
            self.atendimento.sinais.fc < 60 ||
            self.atendimento.sinais.fc > 100
          ) {
            self.selectYes(self.data_modal);
          } else {
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
        }
      }

      if (self.data_modal.discriminator.type == "R") {
        console.log(self.data_modal.discriminator.valor_referencia);
        var valores_referencia =
          self.data_modal.discriminator.valor_referencia.split(",");

        console.log(valores_referencia);
        console.log(self.atendimento.sinais.intensidade_dor);

        if (
          valores_referencia.includes(
            self.atendimento.sinais.intensidade_dor + ""
          )
        ) {
          self.selectYes(self.data_modal);
        } else {
          self.changeActiveItem(self.index_medir, self.data_modal.color_id);
        }
      }

      if (self.data_modal.discriminator.type == "S") {
        console.log("Validando Saturação");
        if (self.data_modal.discriminator.valor_referencia.includes(",")) {
          console.log("entrou aqui");
          var valores_referencia =
            self.data_modal.discriminator.valor_referencia.split(",");

          if (
            Number(self.atendimento.sinais.saturacao) <
              Number(valores_referencia[0]) &&
            self.atendimento.sinais.saturacao_tipo == "Em terapia de O2"
          ) {
            console.log("aquiii", self.atendimento.sinais.saturacao);
            console.log("aquiii", valores_referencia[0]);
            self.selectYes(self.data_modal);
          } else if (
            Number(self.atendimento.sinais.saturacao) <
              Number(valores_referencia[1]) &&
            self.atendimento.sinais.saturacao_tipo == "Ar Atmosférico"
          ) {
            console.log("aquiisssi", self.atendimento.sinais.saturacao);
            console.log("aquiii", valores_referencia[1]);
            console.log(
              "teste",
              self.atendimento.sinais.saturacao < valores_referencia[1] &&
                self.atendimento.sinais.saturacao_tipo == "Ar Atmosférico"
            );
            self.selectYes(self.data_modal);
          } else {
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
        } else {
          var valores_referencia =
            self.data_modal.discriminator.valor_referencia;
          console.log("Validando saturação baixa");
          console.log("Saturacao: ", Number(self.atendimento.sinais.saturacao));
          console.log("valores_referencia: ", Number(valores_referencia));
          if (
            Number(self.atendimento.sinais.saturacao) <
            Number(valores_referencia)
          ) {
            self.selectYes(self.data_modal);
          } else {
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
        }
      }

      if (self.data_modal.discriminator.type == "P") {
        var valores_referencia =
          self.data_modal.discriminator.valor_referencia.split(",");

        if (
          Number(self.atendimento.sinais.pa_sistolica) >=
            Number(valores_referencia[0]) ||
          Number(self.atendimento.sinais.pa_diastolica) >=
            Number(valores_referencia[1])
        ) {
          self.selectYes(self.data_modal);
        } else {
          self.changeActiveItem(self.index_medir, self.data_modal.color_id);
        }
      }

      if (self.data_modal.discriminator.type == "T") {
        if (self.data_modal.discriminator.valor_referencia.includes("<")) {
          console.log("entrou no bloco de menor");
          var valor_referencia =
            self.data_modal.discriminator.valor_referencia.split("<");

          console.log(self.data_modal.discriminator.valor_referencia);

          if (self.atendimento.sinais.temperatura < valor_referencia[1]) {
            self.selectYes(self.data_modal);
          } else {
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
        } else if (
          self.data_modal.discriminator.valor_referencia.includes(",")
        ) {
          console.log("entrou no bloco de virgula");
          var valor_referencia =
            self.data_modal.discriminator.valor_referencia.split(",");

          if (
            self.atendimento.sinais.temperatura >= valor_referencia[0] &&
            self.atendimento.sinais.temperatura <= valor_referencia[1]
          ) {
            self.selectYes(self.data_modal);
          } else {
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
        } else {
          console.log("entrou nesse bloco");
          if (
            self.atendimento.sinais.temperatura >=
            self.data_modal.discriminator.valor_referencia
          ) {
            self.selectYes(self.data_modal);
          } else {
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
        }
      }

      if (self.data_modal.discriminator.type == "GC") {
        //Verifica o tipo de glicemia foi informado
        // if(self.atendimento.sinais.glicemia_cetose == ''){
        //   self.$message('Ops', 'Você precisa informar o tipo de glicemia', "error");
        //   $("#modalGlicemia").modal("toggle");
        //   return;
        // }

        if (self.data_modal.discriminator.title.includes("cetose")) {
          if (
            self.atendimento.sinais.glicemia_cetose == "Sem sinais de acidose"
          ) {
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
            return;
          }
        }

        if (self.data_modal.discriminator.valor_referencia.includes("<")) {
          var valor_referencia =
            self.data_modal.discriminator.valor_referencia.split("<");

          if (
            Number(self.atendimento.sinais.glicemia) <
            Number(valor_referencia[1])
          ) {
            self.selectYes(self.data_modal);
            console.log("entrou, ", valor_referencia[1]);
          } else {
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
        } else {
          if (
            Number(self.atendimento.sinais.glicemia) >
            Number(self.data_modal.discriminator.valor_referencia)
          ) {
            self.selectYes(self.data_modal);
            console.log("entrou tbm");
          } else {
            self.changeActiveItem(self.index_medir, self.data_modal.color_id);
          }
        }
      }
    },
    selectYes(obj) {
      const self = this;

      self.atendimento.flowchart_item_id = obj.id;
      self.atendimento.flowchart_id = obj.flowchart_id;
      self.flowchart_item_selected = obj;

      $("#modalResumoCenter").modal("toggle");
      // self.getResumo();
    },
    changeActiveItem(index, color_id) {
      const self = this;

      var length = self.flowchart_selected.items.filter(
        (color) => color.color_id == color_id
      ).length;

      self.flowchart_selected.items.filter(
        (color) => color.color_id == color_id
      )[index].active = 0;

      if (index + 1 == length) {
        color_id++;
        index = -1;
      }

      if (
        self.flowchart_selected.items.filter((color) => color.color_id == 4)
          .length > 0
      ) {
        if (color_id < 5) {
          self.flowchart_selected.items.filter(
            (color) => color.color_id == color_id
          )[index + 1].active = 1;
        }
      } else {
        if (color_id < 4) {
          self.flowchart_selected.items.filter(
            (color) => color.color_id == color_id
          )[index + 1].active = 1;
        } else {
          if (self.flowchart_selected.id == 51) {
            swal("Atenção!", "Escolha outro fluxograma.", "info");
            self.last_signals = self.atendimento.sinais;
            self.trauma_maior = true;

            self.saveSignals();

            setTimeout(() => {
              self.restartClassification();
              self.flowchart_selected = {};
            }, 500);

            return;
          }

          swal("Atenção!", "Paciente classificado como VERDE.", "info");

          self.page = 4;
        }
      }

      self.atendimento.classificacao = color_id;

      self.atendimento.flowchart_id = self.flowchart_selected.id;

      if (color_id == 1) {
        // self.scrollToElementWithMargin("vermelho", 100);
      }

      if (color_id == 2) {
        // self.scrollToElementWithMargin("laranja", 100);
      }

      if (color_id == 3) {
        // self.scrollToElementWithMargin("amarelo", 100);
      }

      if (color_id == 4) {
        // self.scrollToElementWithMargin("verde", 100);
      }

      if (color_id == 5) {
        // self.scrollToElementWithMargin("azul", 100);

        swal("Atenção!", "Paciente classificado como AZUL.", "info");

        self.page = 4;
      }
    },

    scrollToElementWithMargin(elementId, marginTop) {
      const element = document.getElementById(elementId);
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;

      // Subtrai a margem que você quer acima do elemento
      const offsetPosition = elementPosition - marginTop;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },

    getFlowcharts: function (id) {
      const self = this;
      const api =
        self.$store.state.api +
        "flowcharts?paginated=false&with[]=items&with[]=discriminators&with[]=specialty";

      axios
        .get(api)
        .then((response) => {
          self.flowcharts = response.data.data;
          self.flowcharts.forEach((element) => {
            element.items[0].active = 1;
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getRazoes: function (id) {
      const self = this;
      const api = self.$store.state.api + "razoes_entrada?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.razoes_entrada = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    saveSignals: function (id) {
      const self = this;
      const api = self.$store.state.api + "atendimento/save-signals";

      self.atendimento.sinais.glasgow = self.glasgowCount;
      self.atendimento.sinais.glasgow_label = self.glasgowLabelTxt;

      axios
        .post(api, self.atendimento)
        .then((response) => {
          self.razoes_entrada = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getNecessidadesEspeciais: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "necessidades_especiais?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.necessidades_especiais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getFluxograma() {
      const self = this;
      let api = self.$store.state.api + "get_flowcharts";

      axios
        .post(api, {
          paciente: self.atendimento.paciente,
          specialty: self.specialty_selected,
          protocol:
            self.protocol_selected.id != "" ? self.protocol_selected.id : null,
        })
        .then((response) => {
          self.flowcharts = response.data;
          self.flowcharts.forEach((element) => {
            element.items[0].active = 1;
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    checkAndSetDefaultProtocol() {
      const self = this;
      if (this.protocols.length === 1) {
        this.protocol_selected = this.protocols[0];

        setTimeout(function () {
          self.getFluxograma();
        }, 1500);
      }
    },
    getSetores: function (id) {
      const self = this;
      const api = self.$store.state.api + "setores?paginated=false&exibir=1";

      axios
        .get(api)
        .then((response) => {
          self.setores = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEspecialidades: function (id) {
      const self = this;
      const api = self.$store.state.api + "specialties?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.specialties = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProtocols: function (id) {
      const self = this;
      const api = self.$store.state.api + "protocols?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.protocols = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    nextPage(obj) {
      const self = this;

      console.log(obj);

      var queixa = null;

      if (self.atendimento.queixa_principal != null) {
        queixa = self.atendimento.queixa_principal.replace(/\s+/g, "");
      }

      if (!queixa) {
        swal(
          "Ops!",
          "Você precisa preencher a queixa da solicitação.",
          "error"
        );
      } else if (queixa.length < 10) {
        swal(
          "Ops!",
          "A queixa precisa conter no mínimo 10 caracteres.",
          "error"
        );
      } else {
        if (obj) {
          self.page = obj;
        } else {
          self.page++;
        }
      }
    },
    toggleFullscreenUser() {
      const self = this;

      if (self.userDataFullscreen) {
        self.userDataFullscreen = false;
      } else {
        self.userDataFullscreen = true;
      }
    },
    getEntity: function (id) {
      const self = this;
      let api =
        self.$store.state.api +
        "atendimentos/" +
        id +
        "?with[]=flowchart&with[]=origin&with[]=paciente";

      self.$loading(true);

      axios
        .get(api, self.atendimento)
        .then((response) => {
          self.atendimento = response.data.data[0];

          self.atendimento.sinais.intensidade_dor = null;

          self.atendimento.sinais.saturacao_tipo = "";
          self.atendimento.sinais.glicemia_tipo = "";
          self.atendimento.sinais.glicemia_cetose = "";
          self.atendimento.sinais.classificacao = 1;
          self.atendimento.sinais.pa_sistolica = "";
          self.atendimento.sinais.pa_diastolica = "";
          self.atendimento.sinais.temperatura = "";
          self.atendimento.sinais.saturacao = "";
          self.atendimento.sinais.glicemia = "";
          self.atendimento.sinais.alergias_btn = false;
          self.atendimento.sinais.hipertensao = false;
          self.atendimento.sinais.diabetes = false;

          self.atendimento.inicio_classificacao = moment().format(
            "YYYY-MM-DD HH:mm:ss"
          );

          self.horas = Math.floor(self.atendimento.chronometer / 3600);
          self.minutos = Math.floor((self.atendimento.chronometer % 3600) / 60);
          self.segundos = self.atendimento.chronometer % 60;

          if (self.atendimento.flag_reclassificacao == 1) {
            self.protocol_selected = self.atendimento.flowchart.protocol;
            self.flowchart_selected = self.atendimento.flowchart;
            self.flowchart_selected.items[0].active = 1;
          }

          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    countdown() {
      this.segundos++;
      if (this.segundos == 60) {
        this.segundos = 0;
        this.minutos++;
      }
      if (this.minutos == 60) {
        this.minutos = 0;
        this.horas++;
      }
      $("#timer").html(
        (this.horas ? this.horas : "00") +
          ":" +
          (this.minutos
            ? this.minutos > 9
              ? this.minutos
              : "0" + this.minutos
            : "00") +
          ":" +
          (this.segundos > 9 ? this.segundos : "0" + this.segundos)
      );
    },
    setarBranco() {
      const self = this;

      self.atendimento.classificacao = 6;
      self.atendimento.flowchart_id = 9999;
      self.page = 3;
    },
  },
  computed: {
    tempo_espera_unidade() {
      if (this.horas != 0) {
        return this.horas + " horas";
      }
    },
    classificacaoLabel() {
      const self = this;

      if (self.atendimento.classificacao == 1) {
        return "Vermelho";
      }

      if (self.atendimento.classificacao == 2) {
        return "Laranja";
      }

      if (self.atendimento.classificacao == 3) {
        return "Amarelo";
      }

      if (self.atendimento.classificacao == 4) {
        return "Verde";
      }

      if (self.atendimento.classificacao == 5) {
        return "Azul";
      }

      if (self.atendimento.classificacao == 6) {
        return "Branco";
      }

      if (!self.atendimento.classificacao) {
        return "Não Classificado";
      }
    },
    classificacaoColor() {
      const self = this;

      if (self.atendimento.classificacao == 1) {
        return "border-left: 8px solid rgb(252, 3, 3)";
      }

      if (self.atendimento.classificacao == 2) {
        return "border-left: 8px solid rgb(252, 123, 3)";
      }

      if (self.atendimento.classificacao == 3) {
        return "border-left: 8px solid rgb(245, 237, 2)";
      }

      if (self.atendimento.classificacao == 4) {
        return "border-left: 8px solid #00923F";
      }

      if (self.atendimento.classificacao == 5) {
        return "border-left: 8px solid #180092";
      }

      if (self.atendimento.classificacao == 6) {
        return "border-left: 8px solid #e2e2e2";
      }

      if (!self.atendimento.classificacao) {
        return "border-left: 8px solid #858181";
      }
    },
    classificacaoBadge() {
      const self = this;

      if (self.atendimento.classificacao == 1) {
        return "btn-classificacao vermelho";
      }

      if (self.atendimento.classificacao == 2) {
        return "btn-classificacao laranja";
      }

      if (self.atendimento.classificacao == 3) {
        return "btn-classificacao amarelo";
      }

      if (self.atendimento.classificacao == 4) {
        return "btn-classificacao verde";
      }

      if (self.atendimento.classificacao == 5) {
        return "btn-classificacao azul";
      }

      if (self.atendimento.classificacao == 6) {
        return "btn-classificacao branco";
      }

      if (!self.atendimento.classificacao) {
        return "btn-classificacao cinza";
      }
    },
    diffIdade() {
      const self = this;

      var a = moment();
      var b = moment(self.atendimento.paciente.data_nascimento);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");

      if (years < 100) {
        return years + " anos, " + months + " meses, " + days + " dias";
      }
    },
    imc() {
      var imc =
        this.atendimento.peso /
        (this.atendimento.altura * this.atendimento.altura);
      return Math.round(imc);
    },
    glasgowCount() {
      var returnVal =
        Number(this.glasgow.verbal) +
        Number(this.glasgow.motora) +
        Number(this.glasgow.espontanea);

      if (isNaN(returnVal)) {
        return "Não classificado";
      }
      return Math.round(returnVal);
    },
    glasgowLabel() {
      var string = "";

      switch (this.glasgow.verbal) {
        case "5":
          string += "<strong>Resposta verbal:</strong> Orientada ";
          break;
        case "4":
          string += "<strong>Resposta verbal:</strong> Confusa ";
          break;
        case "3":
          string += "<strong>Resposta verbal:</strong> Palavras inapropriadas ";
          break;
        case "2":
          string +=
            "<strong>Resposta verbal:</strong> Palavras incompreensivas ";
          break;
        case "1":
          string += "<strong>Resposta verbal:</strong> Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.glasgow.espontanea) {
        case "4":
          string += "<strong>Resposta ocular:</strong> Espontânea ";
          break;
        case "3":
          string += "<strong>Resposta ocular:</strong> À Voz ";
          break;
        case "2":
          string += "<strong>Resposta ocular:</strong> À Dor ";
          break;
        case "1":
          string += "<strong>Resposta ocular:</strong> Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.glasgow.motora) {
        case "6":
          string += "<strong>Resposta motora:</strong> Obedece comandos ";
          break;
        case "5":
          string += "<strong>Resposta motora:</strong> Localiza dor ";
          break;
        case "4":
          string += "<strong>Resposta motora:</strong> Movimento de retirada ";
          break;
        case "3":
          string += "<strong>Resposta motora:</strong> Flexão anormal ";
          break;
        case "2":
          string += "<strong>Resposta motora:</strong> Extensão anormal ";
          break;
        case "1":
          string += "<strong>Resposta motora:</strong> Nenhuma ";
          break;
        default:
          string += "";
      }

      return string;
    },

    glasgowLabelTxt() {
      var string = "";

      switch (this.glasgow.verbal) {
        case "5":
          string += "Resposta verbal: Orientada ";
          break;
        case "4":
          string += "Resposta verbal: Confusa ";
          break;
        case "3":
          string += "Resposta verbal: Palavras inapropriadas ";
          break;
        case "2":
          string += "Resposta verbal: Palavras incompreensivas ";
          break;
        case "1":
          string += "Resposta verbal: Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.glasgow.espontanea) {
        case "4":
          string += "Resposta ocular: Espontânea ";
          break;
        case "3":
          string += "Resposta ocular: À Voz ";
          break;
        case "2":
          string += "Resposta ocular: À Dor ";
          break;
        case "1":
          string += "Resposta ocular: Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.glasgow.motora) {
        case "6":
          string += "Resposta motora: Obedece comandos ";
          break;
        case "5":
          string += "Resposta motora: Localiza dor ";
          break;
        case "4":
          string += "Resposta motora: Movimento de retirada ";
          break;
        case "3":
          string += "Resposta motora: Flexão anormal ";
          break;
        case "2":
          string += "Resposta motora: Extensão anormal ";
          break;
        case "1":
          string += "Resposta motora: Nenhuma ";
          break;
        default:
          string += "";
      }

      return string;
    },
    // getResumo() {

    // },
  },
  mounted() {
    const self = this;

    const asyncFunctions = [
      self.getSetores(),
      self.getEspecialidades(),
      self.getNecessidadesEspeciais(),
      self.getRazoes(),
    ];

    const id = self.$route.params.id;
    if (id) {
      asyncFunctions.push(self.getEntity(id));
    }

    Promise.all(asyncFunctions)
      .then(() => {
        self.countdown();

        if (id) {
          return self.getProtocols();
        }
      })
      .then(() => {
        self.checkAndSetDefaultProtocol();
        self.interval = setInterval(this.countdown, 1000);

        setTimeout(function () {
          self.user = self.$store.state.user;
        }, 200);
      })
      .catch((error) => {
        console.error("Erro durante a inicialização:", error);
      });
  },
  watch: {
    protocols: "checkAndSetDefaultProtocol",
  },

  created() {},
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.escolha_outro {
  border: 1px #000 solid;
  padding: 50px;
  font-weight: bold;
  font-size: 20px;
}

.bg-setor {
  min-height: 45px;
  background-color: #eaeaea;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: 0.4s;
}

.bg-setor:hover {
  background-color: #c1c1c1;
}

.bg-setor.active {
  background-color: #15488a;
  color: white;
}

.row-setor {
  padding: 5px;
}

.row-especialidade {
  padding: 5px;
}

.dor-escala {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin: 0 auto;
  flex-direction: row;
  color: #000000;
}

.escala-item {
  width: 80px;
  height: 80px;
  border: 1px solid #919191;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  margin-left: 2px;
  border-radius: 5px;
}
.escala-item-icone {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  margin-left: 2px;
  border-radius: 5px;
}

.escala-item-active {
  border: 4px solid #000;
  margin-top: -5px;
  margin-left: 5px;
  margin-right: 10px;
  font-size: 25px;
}

.escala-item:last-child {
  margin-right: 0;
}

.escala-item.dor-level-0 {
  background-color: rgb(243, 243, 243);
}

.escala-item.dor-level-1 {
  background-color: rgb(32, 192, 53);
}

.escala-item.dor-level-2 {
  background-color: rgb(32, 192, 53);
}

.escala-item.dor-level-3 {
  background-color: rgb(32, 192, 53);
}

.escala-item.dor-level-4 {
  background-color: rgb(32, 192, 53);
}

.escala-item.dor-level-5 {
  background-color: #fc0;
}

.escala-item.dor-level-6 {
  background-color: #fc0;
}

.escala-item.dor-level-7 {
  background-color: #fc0;
}

.escala-item.dor-level-8 {
  background-color: #ff4800;
}

.escala-item.dor-level-9 {
  background-color: #ff4800;
}

.escala-item.dor-level-10 {
  background-color: #ff4800;
}

.escala-label {
  font-size: 24px;
}

.face {
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
}

.tabela-imc {
  width: 100%;
  max-width: 600px;
  margin: 0;
  text-align: center;
  font-weight: bold;
}

.tabela-imc th,
.tabela-imc td {
  padding: 10px;
}

.piscar {
  text-align: center;
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.boxSelecionavel.disabled {
  pointer-events: none; /* Desabilita interações */
  opacity: 0.5; /* Torna visualmente desabilitado */
}

.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}
</style>
