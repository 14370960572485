import Crud from '../views/Outcome/Crud'
import Entity from '../views/Outcome/Add'

let routes = {
    path: '/outcome',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/outcomes/add',
            name: 'NewOutcome',
            component: Entity,
            meta: {
                pageTitle: 'Desfecho',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Adicionar Desfecho',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/outcomes/edit/:id?',
            name: 'EditOutcome',
            component: Entity,
            meta: {
                pageTitle: 'Desfecho',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Editar Desfecho',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/outcomes',
            name: 'list-outcomes',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Desfechos',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Desfechos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
